import Result from '@/modules/core/models/result.class'
import axios from 'axios'
import {
  mapElectionFromElectionDTO,
  mapElectionResultFromElectionResultDTO,
  mapElectionResultFromParliamentaryElectionResultDto,
} from '../mappers/election.mapper'
import type ElectionDto from '../dtos/election.dto'

import parliamentaryResult from '@/assets/election/parliamentary_result.json'
import type { ParliamentarySlipDto, SlipsDto } from '../dtos/slip.dto'
import { openInsightApi } from '@/modules/core/services/api-openinsight.service'
import { mapSlipFromParliamentarySlipDto } from '../mappers/slip.mappers'
import { VerificationStatus } from '../enums/verification-status.enum'
import { openPollApi } from '@/modules/core/services/api-openpoll.service'
import type { ListResponse } from '@/modules/core/interfaces/api-response.interfaces'
import type { ElectionResultDto, ParliamentaryElectionResultDto } from '../dtos/election-result.dto'
export default class ParliamentaryElectionService {
  async getParliamentaryElections() {
    try {
      const responses = await Promise.all(
        parliamentaryResult.map((e: string) => {
          return axios.get<ElectionDto>(
            `/data/election/parliamentary_result/${e}.json`
          )
        })
      )
      const elections = []
      for (const response of responses) {
        const election = response.data
        elections.push(election)
      }
      const mappedElections = elections.map((dto) =>
        mapElectionFromElectionDTO(dto)
      )
      return Result.success(mappedElections)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getConstituencyElectionResults() {
    try {
      const response = await axios.get<ElectionResultDto[]>('/data/election/parliamentary_result_2020.json')

      const mappedElections = response.data.map(mapElectionResultFromElectionResultDTO)
      return Result.success(mappedElections)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getConstituencyElectionResultsRemote() {
    try {
      const response = await openPollApi.get<ListResponse<ParliamentaryElectionResultDto>>('v1/ParliamentaryResults/MondayPollingCenter')

      const mappedElections = response.data!.map(mapElectionResultFromParliamentaryElectionResultDto)
      return Result.success(mappedElections)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getSlips(paginate: boolean = false) {
    try {
      const url = paginate ? '/v1/parliamentary_slips' : '/v1/parliamentary_slips'
      const response = await openInsightApi.get<{ slips: SlipsDto }>(url)
      const electionResults = response.slips.data.map(dto => mapSlipFromParliamentarySlipDto(dto as ParliamentarySlipDto))
      return Result.success(electionResults)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getElectionResults(paginate: boolean = false) {
    try {
      const url = paginate ? '/v1/slips' : '/v1/fetch_all_slips'
      const response = await openInsightApi.get<{ slips: SlipsDto }>(url)
      const electionResults = response.slips.data.map(dto => mapSlipFromParliamentarySlipDto(dto as ParliamentarySlipDto))
      return Result.success(electionResults)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getResultsMonday(status: VerificationStatus = VerificationStatus.VERIFIED) {
    try {
      const url = status ? `/v1/ParliamentaryResults?status=${status}` : '/v1/ParliamentaryResults'
      const response = await openPollApi.get<ListResponse<ParliamentaryElectionResultDto>>(url)
      const electionResults = response.data!.map(mapElectionResultFromParliamentaryElectionResultDto);
      return Result.success(electionResults)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }


}
