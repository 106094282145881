import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useForm } from 'vee-validate'
import { object, string, number } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import PollingCenterService from '../services/polling-center-api.service'
import { useBaseStore } from '@/modules/core/stores/base.store'

import { emptyPollingCenter, type PollingCenter } from '../models/polling-center.model'
import { usePollingCenterIndexStore } from './polling-center-index.store'

// const baseStore = useBaseStore()
// const apiService = new PollingCenterService()

const schema = toTypedSchema(
  object({
    region: string().required().min(4).label('region'),
    constituency: string().required().min(4).label('constituency'),
    electoralArea: string().nullable().min(4).label('electoralArea'),
    code: string().required().min(4).label('code'),
    name: string().required().min(4).label('Name'),
    latitude: number().required().label('latitude'),
    longitude: number().required().label('longitude'),
    agentId: string().required().min(4).label('agentId'),
    agentName: string().required().min(4).label('agentName'),
  })
)

export const usePollingCenterCreateOrEditFormStore = defineStore(
  'pollingCenterCreateOrEditForm',
  () => {
    const { defineComponentBinds, setFieldValue, handleSubmit, errors } = useForm({
      validationSchema: schema
    })

    const region = defineComponentBinds('region')
    const constituency = defineComponentBinds('constituency')
    const electoralArea = defineComponentBinds('electoralArea')
    const code = defineComponentBinds('code')
    const name = defineComponentBinds('name')
    const latitude = defineComponentBinds('latitude')
    const longitude = defineComponentBinds('longitude')
    const agentId = defineComponentBinds('agentId')
    const agentName = defineComponentBinds('agentName')

    const showPollingCenterDialog = ref(false)
    const isSubmitting = ref(false)



    const pollingCenter = ref<PollingCenter>(emptyPollingCenter)
    const isEditing = ref<boolean>(false)

    const createPollingCenter = () => {
      isEditing.value = false
      pollingCenter.value = emptyPollingCenter
      showPollingCenterDialog.value = true
    }

    const editPollingCenter = (editPollingCenter: PollingCenter) => {
      isEditing.value = true
      pollingCenter.value = editPollingCenter

      setFieldValue('region', editPollingCenter.region)
      setFieldValue('constituency', editPollingCenter.constituency)
      setFieldValue('electoralArea', editPollingCenter.electoralArea)
      setFieldValue('code', editPollingCenter.code)
      setFieldValue('name', editPollingCenter.name)
      setFieldValue('latitude', editPollingCenter.latitude)
      setFieldValue('longitude', editPollingCenter.longitude)
      setFieldValue('agentId', editPollingCenter.agentId)
      setFieldValue('agentName', editPollingCenter.agentName)

      showPollingCenterDialog.value = true
    }

    const onSavePollingCenter = handleSubmit((values) => {
      savePollingCenter();
    })

    const savePollingCenter = async () => {
      isSubmitting.value = true;

      // const result = await apiService.postOrPatchPollingCenter(pollingCenter.value, !isEditing.value ? 'post' : 'patch');

      // result.fold(
      //   (value) => {
      //     isSubmitting.value = false;
      //     showPollingCenterDialog.value = false
      //     baseStore.showToast({
      //       severity: 'success',
      //       summary: PollingCenterUiConstants.TOAST_SUMMARY,
      //       detail: `PollingCenter with Id ${value} Saved`,
      //       life: 3000
      //     });
      //     isEditing.value = false;
      //     usePollingCenterIndexStore().getPollingCenters()
      //   },
      //   (error) => {
      //     isSubmitting.value = false;
      //     baseStore.showToast({
      //       severity: 'error',
      //       summary: PollingCenterUiConstants.TOAST_SUMMARY,
      //       detail: error.message,
      //       life: 3000
      //     });
      //     isEditing.value = false;
      //   },
      // );
    };

    return {
      errors,
      region,
      constituency,
      electoralArea,
      code,
      name,
      latitude,
      longitude,
      agentId,
      agentName,
      pollingCenter,
      showPollingCenterDialog,
      isSubmitting,
      createPollingCenter,
      editPollingCenter,
      onSavePollingCenter
    }
  }
)
