<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import 'leaflet/dist/leaflet.css'
import '@/assets/styles/map/styles.css';

import { useMapUiStore } from '../stores/ui.store'
import { useVoronoiConstituencyMapStore } from '../stores/voronoi-constituency-map.store'

import VoterRegistrationStats from '@/modules/voter/components/RegisteredVoterStats.vue'

import MapHeader from '../components/MapHeader.vue'
import Legend from '../components/Legend.vue'

import { useVoterStore } from '../../voter/stores/voter.store';
import { toSnakeCase, toTitleCase, trimAndLowercase } from '../../core/utils/string.util';

export interface Props {
  region: string;
  constituency: string;
}

const props = defineProps<Props>()

const mapContainer = ref<HTMLElement | null>(null)

const mapUiStore = useMapUiStore()
const voronoiConstituencyStore = useVoronoiConstituencyMapStore()
const voterStore = useVoterStore()

function resetStore() {
  mapUiStore.resetStore()
  voronoiConstituencyStore.resetStore()
}


onMounted(async () => {
  const region = toSnakeCase(props.region)
  const constituency = toSnakeCase(props.constituency)

  await Promise.all([
    // voronoiConstituencyStore.getConstituencyByRegionShapeFile(region, constituency),
    voronoiConstituencyStore.getConstituencyShapeFileByConstituency(trimAndLowercase(props.constituency)),
    voronoiConstituencyStore.getPollingCentersCount(region),
    voterStore.getRegionRegisteredVoters(region),
  ]);
  if (mapContainer.value) {
    voronoiConstituencyStore.initializeMap(mapContainer.value)
    voronoiConstituencyStore.initializeLayers()
  }
})

const mapHeaderTitle = computed(() => {
  const constituency = props.constituency.toLocaleUpperCase()
  return `${constituency} CONSTITUENCY MAP`
})

const workInProgress = computed(() => {
  const constituency = toTitleCase(props.constituency)
  const qwerty = voronoiConstituencyStore.pollingStationsCountSummary(constituency).value
  return `WORK IN PROGRESS - ${qwerty}`
})
</script>

<template>
  <div class="parent">
    <div class="presidential-container">
      <Tag icon="pi pi-exclamation-triangle" severity="warning" :value="workInProgress" class="work-in-progress" />
      <MapHeader :title="mapHeaderTitle" />

      <div class="presidential">
        <div class="map-statistics">
          <div class="map-statistics-container">
            <div class="map-statistics-content">
              <VoterRegistrationStats previousYear="2020" currentYear="2024"
                :previousYearRegisteredVoters="voterStore.pollingStationRegisteredVotersSummary().value?.previousYearRegisteredVoters"
                :currentYearRegisteredVoters="voterStore.pollingStationRegisteredVotersSummary().value?.currentYearRegisteredVoters"
                :percentageChange="voterStore.pollingStationRegisteredVotersSummary().value?.percentage"
                :absoluteChange="voterStore.pollingStationRegisteredVotersSummary().value?.net" :summary="null">
              </VoterRegistrationStats>
            </div>
            <div class="map-statistics-legend">
              <Button class="control-button" @click="voronoiConstituencyStore.toggleLegend" text>
                {{ voronoiConstituencyStore.isLegendVisible ? 'Hide Legend' : 'Show Legend' }}
              </Button>
              <Legend v-if="voronoiConstituencyStore.isLegendVisible" :items="voronoiConstituencyStore.legendItems"
                title="Percentage Change in Registered Voters" :horizontal="true" />
            </div>
          </div>
        </div>

        <div class="map-content">
          <div class="map-controls">
            <!-- 
              <Button class="control-button" @click="voronoiConstituencyStore.fitBounds" text>Resize Map</Button> 
            <Button class="control-button" @click="voronoiConstituencyStore.showCentersOnMap" text>showCentersOnMap
            </Button>
            -->
            <Button v-if="!voronoiConstituencyStore.isLoading" class="control-button" @click="resetStore"
              text>Reset</Button>
            <ProgressSpinner v-if="voronoiConstituencyStore.isLoading" style="width: 25px; height: 25px" strokeWidth="8"
              fill="var(--surface-ground)" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
            <Button v-if="voronoiConstituencyStore.isLoading" label="Loading Constituency Map, Please wait..."
              severity="info" text />
          </div>

          <div ref="mapContainer" class="map-container"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.work-in-progress {
  font-size: 1.1rem;
}

.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
  color: #333;
}
</style>
