<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import 'leaflet/dist/leaflet.css'
import '@/assets/styles/map/styles.css';

import { LayerLevel } from '@/modules/map/enums/layer-level.enum'
import { ElectionType } from '@/modules/election/enums/election-type.enum'

import { useDataStore } from '../../../data/stores/data.store'
import { useElectionMapLiveStore } from '../../../election/stores/live/election-map-live.store'
import { useVoterStore } from '../../../voter/stores/voter.store'
import { useMapUiStore } from '../../stores/ui.store'
import { usePollingStationStore } from '../../../polling-center/stores/polling-station.store'
import { useParliamentaryMapLiveStore } from '../../stores/live/parliamentary-map-live.store'

import ElectionSummaryLive from '@/modules/election/components/ElectionSummaryLive.vue'

import MapHeader from '../../components/MapHeader.vue'

const mapContainer = ref<HTMLElement | null>(null)
const dataStore = useDataStore()
const electionStore = useElectionMapLiveStore()
const voterStore = useVoterStore()
const mapUiStore = useMapUiStore()
const pollingStationStore = usePollingStationStore()
const parliamentaryLiveMapStore = useParliamentaryMapLiveStore()

const showRegionNewButton = computed(() => parliamentaryLiveMapStore.currentLevel !== LayerLevel.REGION_SIXTEEN)
const showRegionOldButton = computed(() => parliamentaryLiveMapStore.currentLevel !== LayerLevel.REGION_TEN)

const showConstituencyButton = computed(
  () =>
    parliamentaryLiveMapStore.currentLevel !== LayerLevel.CONSTITUENCY &&
    parliamentaryLiveMapStore.currentLevel !== LayerLevel.REGION_SIXTEEN_CONSTITUENCY
)

function resetStore() {
  voterStore.resetStore()
  mapUiStore.resetStore()
  parliamentaryLiveMapStore.resetStore()
}

// Define observer outside the lifecycle hooks
let observer: IntersectionObserver | null = null;

const observerCallback = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      electionStore.startInterval(ElectionType.PARLIAMENTARY); // Start API polling
    } else {
      electionStore.stopInterval(); // Stop API polling
    }
  });
};

onMounted(async () => {
  await Promise.all([
    dataStore.getShapeFiles(),
    dataStore.getGroupedData(),
    pollingStationStore.getPollingStations(),
    electionStore.getParliamentaryElectionResults(),
  ]);
  observer = new IntersectionObserver(observerCallback);
  if (mapContainer.value) {
    parliamentaryLiveMapStore.initializeMap(mapContainer.value)
    parliamentaryLiveMapStore.initializeLayers()
    observer.observe(mapContainer.value);
  }
})

onUnmounted(() => {
  if (observer && mapContainer.value) {
    observer.unobserve(mapContainer.value);
  }
  electionStore.stopInterval(); // Ensure interval stops on unmount
  observer = null;
});
</script>

<template>
  <div class="parent">
    <div class="parliamentary-container">
      <!-- <Tag icon="pi pi-exclamation-triangle" severity="warning" value="WORK IN PROGRESS - DO NOT USE"
        class="work-in-progress" /> -->
      <MapHeader title="PARLIAMENTARY RESULTS MAP - LIVE" />

      <div class="parliamentary">
        <div class="map-statistics">
          <div class="map-statistics-container">
            <div class="map-statistics-content">
              <ElectionSummaryLive :summary="electionStore.parliamentaryElectionSummary" />
            </div>
          </div>
        </div>

        <div class="map-content">
          <div class="map-controls">
            <!-- <Dropdown v-model="electionStore.selectedPollType" :options="electionStore.pollTypes"
              placeholder="Select Poll Type" checkmark :highlightOnSelect="true" /> -->
            <MultiSelect v-if="parliamentaryLiveMapStore.currentLevel === LayerLevel.REGION_SIXTEEN_CONSTITUENCY"
              v-model="parliamentaryLiveMapStore.selectedSixteenRegions" :options="dataStore.sixteenRegions" filter
              optionLabel="name" placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    parliamentaryLiveMapStore.selectedSixteenRegions ?
                      parliamentaryLiveMapStore.selectedSixteenRegions.length : 0
                  }}</b>
                  item{{
                    (parliamentaryLiveMapStore.selectedSixteenRegions ?
                      parliamentaryLiveMapStore.selectedSixteenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="parliamentaryLiveMapStore.currentLevel === LayerLevel.REGION_TEN_CONSTITUENCY"
              v-model="parliamentaryLiveMapStore.selectedTenRegions" :options="dataStore.tenRegions" filter
              optionLabel="name" placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    parliamentaryLiveMapStore.selectedTenRegions ? parliamentaryLiveMapStore.selectedTenRegions.length :
                      0
                  }}</b>
                  item{{
                    (parliamentaryLiveMapStore.selectedTenRegions ? parliamentaryLiveMapStore.selectedTenRegions.length :
                      0)
                      >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="parliamentaryLiveMapStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="parliamentaryLiveMapStore.selectedConstituencies" :options="dataStore.constituencies" filter
              optionLabel="name" placeholder="Select Constituencies" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    parliamentaryLiveMapStore.selectedConstituencies
                      ? parliamentaryLiveMapStore.selectedConstituencies.length
                      : 0
                  }}</b>
                  item{{
                    (parliamentaryLiveMapStore.selectedConstituencies
                      ? parliamentaryLiveMapStore.selectedConstituencies.length
                      : 0) > 1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <Dropdown v-if="parliamentaryLiveMapStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="parliamentaryLiveMapStore.selectedGroupedData" :options="dataStore.groupedData"
              optionLabel="name" placeholder="Select Grouped Constituencies" checkmark :highlightOnSelect="true" />

            <Button class="control-button" v-if="showRegionNewButton"
              @click="parliamentaryLiveMapStore.setActiveLayer(LayerLevel.REGION_SIXTEEN)" text>
              16 Regions
            </Button>
            <Button class="control-button" v-if="showRegionOldButton"
              @click="parliamentaryLiveMapStore.setActiveLayer(LayerLevel.REGION_TEN)" text>
              10 Regions
            </Button>
            <Button class="control-button" v-if="showConstituencyButton"
              @click="parliamentaryLiveMapStore.setActiveLayer(LayerLevel.CONSTITUENCY)" text>
              Constituencies
            </Button>

            <Button class="control-button" @click="parliamentaryLiveMapStore.fitBounds" text>Resize Map</Button>
            <Button class="control-button" @click="parliamentaryLiveMapStore.toggleColorMode" text>Toggle Color</Button>
            <Button class="control-button" @click="resetStore" text>Reset</Button>
          </div>

          <div ref="mapContainer" class="map-container"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.work-in-progress {
  font-size: 1.1rem;
}

.presidential-container,
.parliamentary-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}
</style>
