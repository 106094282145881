<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { ElectionType } from '../enums/election-type.enum';

import { useElectionIndexStore } from '../stores/election-index.store'
import { useElectionSlipCreateOrEditFormStore } from '../stores/election-create-or-edit-form.store';

import ElectionSlipCreateOrEditForm from './ElectionSlipCreateOrEditForm.vue';

import { formatCompanySubscriptionDate, formatTime } from '../../core/utils/date.util'

const indexStore = useElectionIndexStore()
const createOrEditFormStore = useElectionSlipCreateOrEditFormStore()

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    region: { value: null, matchMode: FilterMatchMode.IN },
    constituency: { value: null, matchMode: FilterMatchMode.IN },
    pollingStationCode: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    pollingStation: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    pinkSheetStampStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const dataTableContainer = ref<HTMLElement | null>(null)

// Define observer outside the lifecycle hooks
let observer: IntersectionObserver | null = null;

const observerCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            indexStore.startInterval(ElectionType.PRESIDENTIAL); // Start API polling
        } else {
            indexStore.stopInterval(); // Stop API polling
        }
    });
};

onMounted(async () => {
    indexStore.setChartOptions()
    indexStore.getPresidentialSlips()

    observer = new IntersectionObserver(observerCallback);
    if (dataTableContainer.value) {
        observer.observe(dataTableContainer.value);
    }
})

onUnmounted(() => {
    if (observer && dataTableContainer.value) {
        observer.unobserve(dataTableContainer.value);
    }
    indexStore.stopInterval(); // Ensure interval stops on unmount
    observer = null;
});

const getSeverity = (status: string) => {
    switch (status) {
        case 'NOT_PRESENT':
            return 'danger';

        case 'PRESENT':
            return 'success';

        case 'new':
            return 'info';

        case 'negotiation':
            return 'warning';

        case 'renewal':
            return undefined;
    }
}

</script>

<template>
    <div ref="dataTableContainer" class="card">
        <TabView>
            <TabPanel header="Table">
                <DataTable v-model:filters="filters" v-model:selection="indexStore.selectedSlips"
                    :value="indexStore.presidentialSlips" :loading="indexStore.isLoading" stripedRows scrollable
                    scrollHeight="flex" removableSort paginator :rows="10" :rowsPerPageOptions="[10, 20, 30, 40, 50]"
                    dataKey="id" filterDisplay="row"
                    :globalFilterFields="['region', 'constituency', 'pollingStationCode', 'pollingStation', 'pinkSheetStampStatus']"
                    tableStyle="min-width: 30rem">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Manage Presidential Slips</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                                <Button icon="pi pi-file-export" severity="success" label="Export as CSV"
                                    @click="indexStore.exportDataAsCsv()" />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="actions" header="Actions" frozen>
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2"
                                @click="createOrEditFormStore.editElectionSlip(slotProps.data)" />
                        </template>
                    </Column>
                    <Column field="region" header="Region" frozen sortable :showFilterMenu="false"
                        style="min-width: 14rem">
                        <template #body="slotProps">
                            {{ slotProps.data.region }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <MultiSelect v-model="filterModel.value" @change="filterCallback()"
                                :options="indexStore.regions" filter placeholder="Any" class="p-column-filter"
                                :maxSelectedLabels="1">

                            </MultiSelect>
                        </template>
                    </Column>
                    <Column field="constituency" header="Constituency" sortable :showFilterMenu="false"
                        style="min-width: 14rem">
                        <template #body="slotProps">
                            {{ slotProps.data.constituency }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <MultiSelect v-model="filterModel.value" @change="filterCallback()"
                                :options="indexStore.constituencies" filter placeholder="Any" class="p-column-filter"
                                :maxSelectedLabels="1">

                            </MultiSelect>
                        </template>
                    </Column>
                    <Column field="pollingStationCode" header="Polling Station Code" sortable style="min-width: 14rem">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                                class="p-column-filter" placeholder="Search by Polling Station Code" />
                        </template>
                    </Column>
                    <Column field="pollingStation" header="Polling Station" sortable style="min-width: 14rem">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                                class="p-column-filter" placeholder="Search by Polling Station Name" />
                        </template>
                    </Column>
                    <Column header="Image" :sortable="false" style="min-width: 5rem">
                        <template #body="slotProps">
                            <Image :src="slotProps.data.imageUrl" alt="Image" preview
                                imageClass="w-3rem border-round" />
                        </template>
                    </Column>
                    <Column field="npp" header="NPP" style="min-width: 5rem">
                        <template #body="slotProps">
                            {{ slotProps.data.npp.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="ndc" header="NDC" style="min-width: 5rem">
                        <template #body="slotProps">
                            {{ slotProps.data.ndc.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="gum" header="GUM" style="min-width: 5rem">
                        <template #body="slotProps">
                            {{ slotProps.data.gum.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="cpp" header="CPP" style="min-width: 5rem">
                        <template #body="slotProps">
                            {{ slotProps.data.cpp.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="gfp" header="GFP" style="min-width: 5rem">
                        <template #body="slotProps">
                            {{ slotProps.data.gfp.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="gcpp" header="GCPP" style="min-width: 5rem">
                        <template #body="slotProps">
                            {{ slotProps.data.gcpp.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="apc" header="APC" style="min-width: 5rem">
                        <template #body="slotProps">
                            {{ slotProps.data.apc.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="lpg" header="LPG" style="min-width: 5rem">
                        <template #body="slotProps">
                            {{ slotProps.data.lpg.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="ndp" header="NDP" style="min-width: 5rem">
                        <template #body="slotProps">
                            {{ slotProps.data.ndp.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="kofiKoranteng" header="Kofi Koranteng" style="min-width: 8rem">
                        <template #body="slotProps">
                            {{ slotProps.data.kofiKoranteng.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="georgeAdu" header="George Adu" style="min-width: 8rem">
                        <template #body="slotProps">
                            {{ slotProps.data.georgeAdu.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="nanaBediako" header="Nana Bediako" style="min-width: 8rem">
                        <template #body="slotProps">
                            {{ slotProps.data.nanaBediako.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="alanKyeremanten" header="Alan Kyeremanten" style="min-width: 8rem">
                        <template #body="slotProps">
                            {{ slotProps.data.alanKyeremanten.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="independent" header="Total Ballots Issued" style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.independent.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="registerVotersB1" header="No. of Voters on Register" style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.registerVotersB1.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="ballotsIssuedVotersC1" header="Total No. of Ballots on Register"
                        style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.ballotsIssuedVotersC1.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="rejectedBallotD1" header="Rejected Ballots" style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.rejectedBallotD1.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="spoiltBallotC3" header="Spoilt Ballots" style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.spoiltBallotC3.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="totalVotesCastRecordedSnc" header="Total Votes Cast Recorded"
                        style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.totalVotesCastRecordedSnc.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="totalValidBallotsRecordedSna" header="Total Valid Votes Recorded"
                        style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.totalValidBallotsRecordedSna.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="biometricVerificationDevice" header="Biometric Verification Device"
                        style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.biometricVerificationDevice.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="manualVerification" header="Manual Verification" style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.manualVerification.toLocaleString() }}
                        </template>
                    </Column>
                    <Column field="pinkSheetStampStatus" header="Pink Sheet Stamp" :showFilterMenu="false"
                        style="min-width: 10rem">
                        <template #body="{ data }">
                            <Tag :value="data.pinkSheetStampStatus"
                                :severity="getSeverity(data.pinkSheetStampStatus)" />
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <Dropdown v-model="filterModel.value" @change="filterCallback()"
                                :options="indexStore.pinkSheetStampStatus" placeholder="Select Status"
                                class="p-column-filter" style="min-width: 12rem" :showClear="true">
                                <template #option="slotProps">
                                    <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                                </template>
                            </Dropdown>
                        </template>
                    </Column>
                    <Column field="reason" header="Reason" style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.reason }}
                        </template>
                    </Column>
                    <Column field="voterName" header="Voter Name" style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ slotProps.data.voterName }}
                        </template>
                    </Column>
                    <Column field="createdAt" header="Created At" style="min-width: 10rem">
                        <template #body="slotProps">
                            {{ formatCompanySubscriptionDate(slotProps.data.createdAt) }} | {{
                                formatTime(slotProps.data.createdAt) }}
                        </template>
                    </Column>

                </DataTable>
            </TabPanel>
            <TabPanel header="Chart">
                <div class="p-fluid grid">
                    <div class="col-12">
                        <div class="card flex justify-content-center">

                            <MultiSelect v-model="indexStore.selectedSixteenRegions" display="chip"
                                :options="indexStore.regions" filter placeholder="Select Regions" :maxSelectedLabels="2"
                                class="w-full md:w-18rem">
                                <template #footer>
                                    <div class="py-2 px-3">
                                        <b>{{
                                            indexStore.selectedSixteenRegions ?
                                                indexStore.selectedSixteenRegions.length : 0
                                        }}</b>
                                        item{{
                                            (indexStore.selectedSixteenRegions ?
                                                indexStore.selectedSixteenRegions.length : 0) >
                                                1
                                                ? 's'
                                                : ''
                                        }}
                                        selected.
                                    </div>
                                </template>
                            </MultiSelect>

                            <MultiSelect v-if="false" v-model="indexStore.selectedConstituencies" display="chip"
                                :options="indexStore.constituencies" filter placeholder="Select Constituencies"
                                :maxSelectedLabels="2" class="w-full md:w-18rem">
                                <template #footer>
                                    <div class="py-2 px-3">
                                        <b>{{
                                            indexStore.selectedConstituencies ?
                                                indexStore.selectedConstituencies.length : 0
                                        }}</b>
                                        item{{
                                            (indexStore.selectedConstituencies ?
                                                indexStore.selectedConstituencies.length : 0) >
                                                1
                                                ? 's'
                                                : ''
                                        }}
                                        selected.
                                    </div>
                                </template>
                            </MultiSelect>
                        </div>

                    </div>
                    <div class="col-12 lg:col-8 xl:col-6">
                        <div class="card">
                            <Chart type="bar" :data="indexStore.voteBarChartData" :options="indexStore.chartOptions"
                                class="h-30rem" />
                        </div>
                    </div>
                    <div class="col-12 lg:col-8 xl:col-6">
                        <div class="card">
                            <Chart type="bar" :data="indexStore.countBarChartData" :options="indexStore.chartOptions"
                                class="h-30rem" />
                        </div>
                    </div>
                    <div class="col-12 lg:col-8 xl:col-6">
                        <div class="card flex justify-content-center">
                            <Chart type="pie" :data="indexStore.voteBarChartData" :options="indexStore.pieChartOptions"
                                class="w-full md:w-30rem" />
                        </div>
                    </div>
                    <div class="col-12 lg:col-8 xl:col-6">
                        <div class="card flex justify-content-center">
                            <Chart type="pie" :data="indexStore.countBarChartData" :options="indexStore.pieChartOptions"
                                class="w-full md:w-30rem" />
                        </div>
                    </div>
                </div>
            </TabPanel>
        </TabView>

        <ElectionSlipCreateOrEditForm />
    </div>
</template>