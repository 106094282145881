<script setup lang="ts">
import { computed } from 'vue'
import '@/assets/styles/election-summary/styles.css';

import type { ElectionSummary } from '../models/election-summary.model';
import ElectoralCard from '@/modules/election/components/ElectoralCard.vue'


interface Props {
    year: string;
    summary: ElectionSummary | null;
}

const props = defineProps<Props>()

const firstLeadingPartyStyle = computed(() => ({
    color: props.summary?.firstLeadingParty.color as string
}))

const secondLeadingPartyStyle = computed(() => ({
    color: props.summary?.secondLeadingParty.color as string
}))

const otherLeadingPartyStyle = computed(() => ({
    color: '#DAA520'
}))

const firstLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.firstLeadingParty.percentage}%`,
    backgroundColor: props.summary?.firstLeadingParty.color as string
}))

const secondLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.secondLeadingParty.percentage}%`,
    backgroundColor: props.summary?.secondLeadingParty.color as string
}))

const otherLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.otherLeadingParty.percentage}%`,
    backgroundColor: props.summary?.otherLeadingParty.color,
    left: `${props.summary?.firstLeadingParty.percentage}%`
}))

</script>
<template>
    <div class="election-results">
        <h1 class="title">{{ props.year }} RESULTS</h1>
        <div class="results-bar">

            <ElectoralCard :style="firstLeadingPartyStyle" :name="props.summary?.firstLeadingParty.name"
                party="Democratic" :electoral-votes="props.summary?.firstLeadingParty.constituencyWon"
                :popular-votes="props.summary?.firstLeadingParty.votes"
                :image-url="props.summary?.firstLeadingParty.image" :color="props.summary?.firstLeadingParty.color" />

            <!-- Results Section -->
            <div class="results-section">
                <div class="bar">
                    <div class="first-leading-party-bar" :style="firstLeadingPartyBarStyle">
                        <span class="percentage-label">{{ props.summary?.firstLeadingParty.percentage }}%</span>
                    </div>
                    <div class="other-leading-party-bar" :style="otherLeadingPartyBarStyle"></div>
                    <div class="second-leading-party-bar" :style="secondLeadingPartyBarStyle">
                        <span class="percentage-label">{{ props.summary?.secondLeadingParty.percentage }}%</span>
                    </div>
                </div>
            </div>

            <ElectoralCard :style="secondLeadingPartyStyle" :name="props.summary?.secondLeadingParty.name"
                party="Democratic" :electoral-votes="props.summary?.secondLeadingParty.constituencyWon"
                :popular-votes="props.summary?.secondLeadingParty.votes"
                :image-url="props.summary?.secondLeadingParty.image" :color="props.summary?.secondLeadingParty.color" />

            <!-- Results Section -->
            <div class="results-section">
                <div class="win-margin">
                    Win margin:
                    <span class="margin-number">{{ props.summary?.margin.toLocaleString()
                        }}</span>
                    votes (<span class="margin-percentage">{{
                        props.summary?.marginPercentage.toFixed(2)
                        }}%</span>)
                </div>
                <div class="others" :style="otherLeadingPartyStyle">
                    <div class="candidate-info">
                        <span class="vote-count">OTHERS</span>
                        <span class="vote-percentage"> | {{
                            props.summary?.otherLeadingParty.percentage }}%</span>
                        <span class="vote-count"> | {{
                            props.summary?.otherLeadingParty.votes.toLocaleString()
                            }}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<style scoped>
.candidate-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 13px;
}
</style>
