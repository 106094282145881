<script setup>
import { ref } from 'vue'

import AppMenuItem from './AppMenuItem.vue'

const model = ref()

model.value = [
  {
    items: [
      { label: 'Home', icon: 'pi pi-fw pi-home', to: '/' },
      {
        label: 'Election',
        items: [
          { label: 'Presidential Slips', icon: 'pi pi-fw pi-user', to: '/election/presidential-slips' },
          { label: 'Parliamentary Slips', icon: 'pi pi-fw pi-user', to: `/election/parliamentary-slips` },
        ]
      },

      { label: 'Polling Centers', icon: 'pi pi-fw pi-building', to: '/polling-center/polling-centers' },
    ]
  }
]

</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>

  </ul>
</template>

<style lang="scss" scoped></style>
