import Result from '@/modules/core/models/result.class'
import axios from 'axios'
import type { PollingStationDto } from '../dtos/polling-station.dto'
import { finderApi } from '@/modules/core/services/api-finder.service'
import type { PollingCenter } from '../models/polling-center.model'
import { mapPollingStationsFromPollingStationsDto } from '../mappers/polling-station.mapper'


export default class PollingCenterApiService {
  async getPollingStations() {
    try {
      const response = await axios.get<PollingStationDto[]>('/data/polling_station/polling_station_2024.json')
      const stations = response.data.map(mapPollingStationsFromPollingStationsDto)
      return Result.success(stations)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getPollingCentersCount(region: string) {
    try {
      const response = await axios.get(`/data/polling_station/count/${region}.json`)
      return Result.success(response.data)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getPollingCenters() {
    try {
      const response = await finderApi.get<{ msg: string, stations: PollingCenter[] }>('stations')
      const center = response.stations
      return Result.success(center)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

}
