import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import type { Login } from '../models/auth.model';
import type AccessToken from '../models/access-token.interface'
import { InfoService } from '@/modules/core/services/info.service';
import type { LoginResponse } from '../models/response.interface';

import AuthApiService from '../services/auth-api.service';
import type { ToastParameters } from '@/modules/core/interfaces/toast.interface';

import router from '@/router'

import { useAppUserStore } from '@/modules/app-user/stores/app-user.store';
import { useFirebaseStore } from '@/modules/firebase/stores/firebase.store';
import { useRecaptchaStore } from './recaptcha.store';

import * as AuthStoreConstants from '../constants/auth-store-constants'
import * as AuthUiConstants from '../constants/auth-ui-constants'
import * as AuthLocalStorageConstants from '../constants/auth-local-storage-constants'
import { log } from '@/modules/core/utils/console.util';

const isProduction = import.meta.env.MODE === 'production'

const authApiService = new AuthApiService();

export const useAuthStore = defineStore(AuthStoreConstants.AUTH, () => {

  const appUserStore = useAppUserStore();
  const firebaseStore = useFirebaseStore();
  const recaptchaStore = useRecaptchaStore();

  const stringifiedAccessToken = localStorage.getItem(AuthLocalStorageConstants.ACCESS_TOKEN);
  const parsedAccessToken = parseAccessToken(stringifiedAccessToken) as AccessToken | null;

  const returnUrl = ref<string>();
  const loggedInCount = ref<string>(localStorage.getItem(AuthLocalStorageConstants.LOGIN_COUNT) ?? "0");
  const mAccessToken = ref<AccessToken | null>(parsedAccessToken);

  const isSubmitting = ref<boolean>(false);
  const toastParams = ref<ToastParameters>();
  const showToast = ref<boolean>(false);

  const isLoggedInExpired = computed<boolean>(() => {
    log('loggedInCount:', loggedInCount.value);
    if (mAccessToken.value) {
      log('isExpired:', isTokenExpired(mAccessToken.value));
    }

    // First check if login count is invalid
    if (Number(loggedInCount.value) <= 0) {
      return true;
    }

    // If no access token exists, user is considered logged out
    if (!mAccessToken.value) {
      return true;
    }
    // Check if token is expired
    return isTokenExpired(mAccessToken.value);
  });

  function isTokenExpired(token: AccessToken): boolean {
    const expirationDate = new Date(token.expiresIn);
    const currentDate = new Date();
    return currentDate > expirationDate;
  }

  const pin = ref('');
  const errorMessage = ref('');

  const mLoginAttempts = ref({
    count: 0,
    lastAttempt: null as string | null,
  });

  const onLogin = async () => {
    // if (!recaptchaStore.recaptchaResponse && isProduction) {
    //   alert('Please complete the reCAPTCHA');
    //   return;
    // }

    try {
      isSubmitting.value = true;
      errorMessage.value = '';

      mLoginAttempts.value.count++;
      mLoginAttempts.value.lastAttempt = new Date().toISOString();

      const [deviceInfo, locationInfo, browserInfo] = await Promise.all([
        InfoService.collectDeviceInfo(),
        InfoService.collectLocationInfo(),
        InfoService.collectBrowserInfo(),
      ]);

      const login: Login = {
        code: pin.value,
        deviceInfo,
        locationInfo,
        browserInfo,
        timestamp: new Date().toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        loginAttemptCount: mLoginAttempts.value.count,
      };

      log("Login: ", login);

      const result = await authApiService.login(login)
      result.fold(
        (value) => {
          const response = value as LoginResponse
          isSubmitting.value = false
          showToast.value = true

          toastParams.value = {
            severity: 'success',
            summary: AuthUiConstants.TOAST_SUMMARY,
            detail: `Welcome back, ${response.user.name}`,
            life: 3000
          }

          mAccessToken.value = response.accessToken

          // Encrypt and store in localStorage
          appUserStore.setAppUser(response.user);

          loggedInCount.value = "1"
          localStorage.setItem(AuthLocalStorageConstants.LOGIN_COUNT, loggedInCount.value);
          localStorage.setItem(
            AuthLocalStorageConstants.ACCESS_TOKEN,
            stringifyAccessToken(mAccessToken.value)
          );

          // firebaseStore.removeToken()

          firebaseStore.setToken
          pin.value = ''

          router.push(returnUrl.value || '/') // redirect to previous url or default to home page
        },
        (error) => {
          isSubmitting.value = false
          errorMessage.value = error.message
          showToast.value = true
          toastParams.value = {
            severity: 'error',
            summary: AuthUiConstants.TOAST_SUMMARY,
            detail: error.message,
            life: 3000
          }
        }
      )

    } catch (e) {
      errorMessage.value = e instanceof Error ? e.message : 'An error occurred';
    } finally {
      isSubmitting.value = false;
    }

  }

  const logout = () => {
    appUserStore.removeAppUser();
    mAccessToken.value = null;
    localStorage.removeItem(AuthLocalStorageConstants.ACCESS_TOKEN);
    firebaseStore.removeToken;
    router.push('/auth/login')
  }

  function stringifyAccessToken(accessToken: AccessToken) {
    return JSON.stringify(accessToken);
  }

  function parseAccessToken(jsonStr: string | null) {
    return jsonStr && jsonStr.length > 0 ? JSON.parse(jsonStr) : null;
  }

  return {
    returnUrl,
    isLoggedInExpired,
    pin,
    errorMessage,
    accessToken: mAccessToken,
    isSubmitting,
    isProduction,
    logout,
    onLogin
  }
})
