export function exportToCSV(data: any[], fileName: string) {
    if (!data || !data.length) {
        console.error("No data available to export.");
        return;
    }

    // Generate CSV headers from object keys
    const headers = Object.keys(data[0]).join(",");
    const rows = data.map(row =>
        Object.values(row)
            .map(value => `"${value}"`)
            .join(",")
    );

    // Combine headers and rows
    const csvContent = [headers, ...rows].join("\n");

    // Create a Blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.csv`;
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
}
