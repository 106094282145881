import axios from 'axios'
import { setupCache, buildWebStorage } from 'axios-cache-interceptor'
import * as ApiConstants from '../constants/api.constants'
import router from '@/router'
import { useAuthStore } from '@/modules/auth/stores/auth.store'

const isProduction = import.meta.env.MODE === 'production'
const baseUrl: string = isProduction ? ApiConstants.BASE_URL_REMOTE : ApiConstants.BASE_URL_LOCAL

const TTL = {
  SHORT: 30 * 1000,          // 30 seconds
  MEDIUM: 15 * 60 * 1000,    // 15 minutes
  LONG: 24 * 60 * 60 * 1000, // 24 hours

  // Custom based on requirements
  STOCK_PRICE: 5 * 1000,     // 5 seconds
  USER_PROFILE: 5 * 60 * 1000 // 5 minutes
}

// Create axios instance with cache
const api = setupCache(
  axios.create({
    baseURL: baseUrl,
    timeout: 1 * 60 * 1000,
    headers: {
      'Content-Type': 'application/json'
    }
  }),
  {
    // Cache configuration
    ttl: 2 * 60 * 1000, // 15 minutes default TTL
    storage: buildWebStorage(localStorage, 'axios-cache:'),
    // Define cache rules using the proper types
    interpretHeader: false, // Disable header interpretation
    methods: ['get'], // Only cache GET requests
    // Custom cache key generation
    generateKey: req => {
      const url = req.url || ''
      // Don't cache auth-related requests
      if (/(auth|login|logout)/i.test(url)) {
        return '' // Empty string will effectively disable caching for this request
      }
      // You can customize how cache keys are generated
      return `${req.method}:${req.url}:${JSON.stringify(req.params)}`
    },
    // Optional: Debug mode
    debug: console.debug
  }
)

// Request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const auth = useAuthStore()
    const token = auth.accessToken

    if (token?.token) {
      config.headers = config.headers || {}
      config.headers.Authorization = `Bearer ${token.token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (!originalRequest._retry) {
            originalRequest._retry = true
            router.push({ name: 'auth.login' })
            console.log('Redirecting to login due to unauthorized access')
          }
          break

        case 403:
          console.error('🔥Error::Forbidden access: ', error.response.data)
          break

        case 500:
          console.error('🔥Error::Server error:', error.response.data)
          break

        default:
          console.error('🔥Error::API Error:', error.response.data)
      }
    } else if (error.request) {
      console.error('🔥Error::Network Error:', error.message)
    } else {
      console.error('🔥Error::Error:', error.message)
    }

    return Promise.reject(error)
  }
)

// Helper methods with cache control
export const openInsightApi = {
  get: async <T>(url: string, config?: any) => {
    const response = await api.get<T>(url, {
      cache: {
        // Override default cache settings if needed
        // ttl: 5 * 60 * 1000, // 5 minutes
        // override: true, // Force cache update
      },
      ...config
    })
    return response.data
  },

  post: async <T>(url: string, data?: any, config?: any) => {
    const response = await api.post<T>(url, data, config)
    return response.data
  },

  put: async <T>(url: string, data?: any, config?: any) => {
    const response = await api.put<T>(url, data, config)
    return response.data
  },

  delete: async <T>(url: string, config?: any) => {
    const response = await api.delete<T>(url, config)
    return response.data
  }
}