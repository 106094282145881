import Result from '@/modules/core/models/result.class'
import axios from 'axios'
import type GroupedData from '../models/grouped_data.model'

export default class GroupedDataApiService {

    async getGroupedData() {
        try {
            const responses = await Promise.all([
                axios.get<GroupedData>(`/data/grouped/border.json`),
                axios.get<GroupedData>(`/data/grouped/eastern_border.json`),
                axios.get<GroupedData>(`/data/grouped/northern_border.json`),
                axios.get<GroupedData>(`/data/grouped/western_border.json`),
                axios.get<GroupedData>(`/data/grouped/costal.json`),
                axios.get<GroupedData>(`/data/grouped/galamsey.json`),
            ])

            const groups: GroupedData[] = []
            for (const response of responses) {
                const group = response.data
                groups.push(group)
            }
            return Result.success(groups)
        } catch (e) {
            const error = e instanceof Error ? e : new Error('An unknown error occurred')
            return Result.failure(error)
        }
    }
}
