const ElectionModule = () => import('./ElectionModule.vue')
import PresidentialSlipIndex from '@/modules/election/views/PresidentialSlipIndex.vue'
import ParliamentarySlipIndex from '@/modules/election/views/ParliamentarySlipIndex.vue'
import ElectionResultBar from '@/modules/election/views/ElectionResultBar.vue'

export const electionModuleRoute = {
  name: 'election',
  path: '/election',
  component: ElectionModule,
  children: [
    {
      path: 'presidential-slips',
      name: 'election.presidential.index',
      component: PresidentialSlipIndex
    },
    {
      path: 'parliamentary-slips',
      name: 'election.parliamentary.index',
      component: ParliamentarySlipIndex
    },
    {
      path: 'election-result-bar',
      name: 'election.result.bar',
      component: ElectionResultBar
    }
  ]
}
