export function hexToRgb(hex: string): { r: number, g: number, b: number } | null {
    // Remove the leading '#' if it's there
    let cleanedHex = hex.replace(/^#/, '');

    // Check if it's a valid 3 or 6 character hex code
    if (cleanedHex.length === 3) {
        cleanedHex = cleanedHex.split('').map(char => char + char).join('');
    }

    if (cleanedHex.length !== 6) {
        return null; // Invalid hex code
    }

    const r = parseInt(cleanedHex.substring(0, 2), 16);
    const g = parseInt(cleanedHex.substring(2, 4), 16);
    const b = parseInt(cleanedHex.substring(4, 6), 16);

    return { r, g, b };
}