export const defaultRegionLayerStyle = {
  color: '#FFFFFF',
  weight: 2,
  opacity: 1,
  fillColor: '#adb5bd',
  fillOpacity: 0.9
}

export const defaultConstituencyLayerStyle = {
  color: '#FFFFFF',
  weight: 1.5,
  opacity: 1.0,
  fillColor: '#adb5bd',
  fillOpacity: 0.9
}

export const defaultPollingStationLayerStyle = {
  color: '#FFFFFF',
  weight: 1.5,
  opacity: 1,
  fillColor: '#adb5bd',
  fillOpacity: 0.7
}

export const highlightLayerStyle = {
  weight: 2,
  color: '#FFF',
  fillOpacity: 0.7
}

export const selectedLayerStyle = {
  weight: 3,
  color: '#FFF',
  fillOpacity: 1
}

export const pollingStation = '#2596be'
export const pollingStationLow = '#2596be'
export const pollingStationHigh = '#2596be'
export const neutral = '#D2B48C'
export const unknown = '#adb5bd'

export const colorCodes = [
  '#FF6B6B',
  '#4ECDC4',
  '#45B7D1',
  '#FFA07A',
  '#98FB98',
  '#DDA0DD',
  '#F0E68C',
  '#87CEFA',
  '#FFDAB9',
  '#7B68EE',
  '#FF69B4',
  '#20B2AA',
  '#B8860B',
  '#32CD32',
  '#FF7F50',
  '#9370DB',
  '#00CED1',
  '#FF4500',
  '#1E90FF',
  '#FFD700',
  '#8A2BE2',
  '#00FA9A',
  '#DC143C',
  '#6A5ACD'
]
