<script setup lang="ts">
import { usePollingCenterCreateOrEditFormStore } from '../stores/polling-center-create-or-edit-form.store'

const createOrEditFormStore = usePollingCenterCreateOrEditFormStore()
</script>

<template>
  <Dialog v-model:visible="createOrEditFormStore.showPollingCenterDialog" :style="{ width: '600px' }"
    header="Polling Center Details" :modal="true" class="p-fluid">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-4">
        <label for="region">Region</label>
        <InputText id="region" type="text" v-bind="createOrEditFormStore.region"
          v-model="createOrEditFormStore.pollingCenter.region" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.region }" />
        <small id="region-help" class="p-error">
          {{ createOrEditFormStore.errors.region }}
        </small>
      </div>
      <div class="field col-12 md:col-6">
        <label for="constituency">Constituency</label>
        <InputText id="constituency" type="text" v-bind="createOrEditFormStore.constituency"
          v-model="createOrEditFormStore.pollingCenter.constituency" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.constituency }" />
        <small id="constituency-help" class="p-error">
          {{ createOrEditFormStore.errors.constituency }}
        </small>
      </div>
      <div class="field col-12">
        <label for="electoralArea">Electoral Area</label>
        <InputText id="electoralArea" type="text" v-bind="createOrEditFormStore.electoralArea"
          v-model="createOrEditFormStore.pollingCenter.electoralArea" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.electoralArea }" />
        <small id="electoralArea-help" class="p-error">
          {{ createOrEditFormStore.errors.electoralArea }}
        </small>
      </div>
      <div class="field col-12 md:col-4">
        <label for="code">Polling Center Code</label>
        <InputText id="code" type="text" v-bind="createOrEditFormStore.code"
          v-model="createOrEditFormStore.pollingCenter.code" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.code }" />
        <small id="code-help" class="p-error">
          {{ createOrEditFormStore.errors.code }}
        </small>
      </div>
      <div class="field col-12 md:col-8">
        <label for="name">Polling Center Name</label>
        <InputText id="name" type="text" v-bind="createOrEditFormStore.name"
          v-model="createOrEditFormStore.pollingCenter.name" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.name }" />
        <small id="name-help" class="p-error">
          {{ createOrEditFormStore.errors.name }}
        </small>
      </div>
      <div class="field col-12 md:col-6">
        <label for="latitude">Latitude</label>
        <InputNumber id="latitude" type="text" v-bind="createOrEditFormStore.latitude"
          v-model="createOrEditFormStore.pollingCenter.latitude" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.latitude }" />
        <small id="latitude-help" class="p-error">
          {{ createOrEditFormStore.errors.latitude }}
        </small>
      </div>
      <div class="field col-12 md:col-6">
        <label for="longitude">Longitude</label>
        <InputNumber id="longitude" type="text" v-bind="createOrEditFormStore.longitude"
          v-model="createOrEditFormStore.pollingCenter.longitude" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.longitude }" />
        <small id="longitude-help" class="p-error">
          {{ createOrEditFormStore.errors.longitude }}
        </small>
      </div>
      <div class="field col-12 md:col-6">
        <label for="agentId">Agent Id</label>
        <InputText id="agentId" type="text" v-bind="createOrEditFormStore.agentId"
          v-model="createOrEditFormStore.pollingCenter.agentId" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.agentId }" />
        <small id="agentId-help" class="p-error">
          {{ createOrEditFormStore.errors.agentId }}
        </small>
      </div>
      <div class="field col-12 md:col-6">
        <label for="agentName">Agent Name</label>
        <InputText id="agentName" type="text" v-bind="createOrEditFormStore.agentName"
          v-model="createOrEditFormStore.pollingCenter.agentName" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.agentName }" />
        <small id="agentName-help" class="p-error">
          {{ createOrEditFormStore.errors.agentName }}
        </small>
      </div>
      <div class="card flex justify-center">
        <Image :src="createOrEditFormStore.pollingCenter.imageUrl" alt="Image" width="250" preview />
      </div>

    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text"
        @click="createOrEditFormStore.showPollingCenterDialog = false" />
    </template>
  </Dialog>
</template>
