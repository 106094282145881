import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

import type PartyVotes from '../models/party_votes.model'
import type ElectionResult from '../models/election-result.model'


import PresidentialElectionService from '../services/presidential-election.service'
import ParliamentaryElectionService from '../services/parliamentary-election.service'

import { hexToRgb } from '@/modules/core/utils/color.util'

import partyInfo from '@/assets/election/party_info.json'
import { trimAndLowercase } from '@/modules/core/utils/string.util'

import ChartDataLabels from 'chartjs-plugin-datalabels';

const presidentialElectionService = new PresidentialElectionService()
const parliamentaryElectionService = new ParliamentaryElectionService()

export const useElectionResultBarStore = defineStore('electionResultBar', () => {

  const mPresidentialElectionResults2020 = ref<ElectionResult[]>([])
  const mPresidentialElectionResults2024 = ref<ElectionResult[]>([])

  const mParliamentaryElectionResults2020 = ref<ElectionResult[]>([])
  const mParliamentaryElectionResults2024 = ref<ElectionResult[]>([])

  const mRegions = ref<string[]>([])
  const mSelectedRegions = ref<string[]>([])

  const mConstituencies = ref<string[]>([])
  const mSelectedConstituencies = ref<string[]>([])

  const mChartOptions = ref();

  const mVoteChartDataPresidential = ref();
  const mVoteChartDataParliamentary = ref();

  const nppVoteChartDataPresidential = ref();
  const ndcVoteChartDataPresidential = ref();
  const othersVoteChartDataPresidential = ref();

  let mApiInterval: ReturnType<typeof setInterval> | null = null;

  // Start the interval
  function startInterval() {
    if (!mApiInterval) {
      mApiInterval = setInterval(getPresidentialElectionResultsS, 60000); // 10 seconds
    }
  }

  const getElectionResultsS = () => {
    getPresidentialElectionResultsS()
    getParliamentaryElectionResultsS()
  }

  const getPresidentialElectionResultsS = () => {
    presidentialElectionService.getConstituencyElectionResultsRemote()
      .then((result) => {
        const electionResults = result.getOrNull();
        if (electionResults) {
          mPresidentialElectionResults2024.value = electionResults as ElectionResult[];
          setChartDataPresidential()
        }
      })
      .catch((error) => {
        console.error("Failed to fetch election results:", error);
      });
  };

  watch(mSelectedConstituencies, (selectedElection) => {
    if (selectedElection) {
      setChartDataPresidential()
    }
  })

  const getParliamentaryElectionResultsS = () => {
    parliamentaryElectionService.getConstituencyElectionResults().then(
      (result) => {
        if (result.getOrNull()) {
          mParliamentaryElectionResults2024.value = result.getOrNull() as ElectionResult[]
          setChartDataParliamentary()
        }
      }
    )
      .catch((error) => {
        console.error("Failed to fetch election results:", error);
      });
  }


  // Stop the interval
  function stopInterval() {
    if (mApiInterval) {
      clearInterval(mApiInterval);
      mApiInterval = null;
    }
  }

  function getSelectedConstituencies() {
    return mSelectedConstituencies.value.length < 1 ? mConstituencies.value : mSelectedConstituencies.value;
  }

  const setChartDataPresidential = () => {
    const presidentialElectionResults2020: ElectionResult[] = mPresidentialElectionResults2020.value
    const presidentialElectionResults2024: ElectionResult[] = mPresidentialElectionResults2024.value

    const filteredPresidentialElectionResults2020 = mSelectedConstituencies.value.length < 1
      ? presidentialElectionResults2020
      : presidentialElectionResults2020.filter(result =>
        mSelectedConstituencies.value.some(constituency => trimAndLowercase(constituency) === trimAndLowercase(result.constituency))
      )

    const filteredPresidentialElectionResults2024 = mSelectedConstituencies.value.length < 1
      ? presidentialElectionResults2024
      : presidentialElectionResults2024.filter(result =>
        mSelectedConstituencies.value.some(constituency => trimAndLowercase(constituency) === trimAndLowercase(result.constituency))
      )

    const sortedPresidentialResults2020 = accumulateAndSortVotes(filteredPresidentialElectionResults2020)
    const sortedPresidentialResults2024 = accumulateAndSortVotes(filteredPresidentialElectionResults2024)

    const [first2020, second2020] = sortedPresidentialResults2020
    const other2020 = sortedPresidentialResults2020.slice(2);
    const otherParties2020: PartyVotes = {
      party: { name: 'Other' },
      votes: other2020.reduce((sum, party) => sum + party.votes, 0),
      votesRatio: other2020.reduce((sum, party) => sum + (party.votesRatio ?? 0), 0)
    }

    const [first2024, second2024] = sortedPresidentialResults2024
    const other2024 = sortedPresidentialResults2024.slice(2);
    const otherParties2024: PartyVotes = {
      party: { name: 'Other' },
      votes: other2024.reduce((sum, party) => sum + party.votes, 0),
      votesRatio: other2024.reduce((sum, party) => sum + (party.votesRatio ?? 0), 0)
    }

    const ndc2020 = first2020.party.name === 'NDC' ? first2020 : second2020
    const npp2020 = first2020.party.name === 'NPP' ? first2020 : second2020

    const ndc2024 = first2024.party.name === 'NDC' ? first2024 : second2024
    const npp2024 = first2024.party.name === 'NPP' ? first2024 : second2024

    const results2020 = [ndc2020, npp2020, otherParties2020]
    const results2024 = [ndc2024, npp2024, otherParties2024]

    const ndc2020And24 = [ndc2020, ndc2024]
    const ndcVotesData2024 = ndc2020And24.map((result) => result.votes)
    const ndcVotesLabel = ["NDC\n2020", "NDC\n2024"]

    const npp2020And24 = [npp2020, npp2024]
    const nppVotesData2024 = npp2020And24.map((result) => result.votes)
    const nppVotesLabel = ["NPP\n2020", "NPP\n2024"]

    const others2020And24 = [otherParties2020, otherParties2024]
    const othersVotesData2024 = others2020And24.map((result) => result.votes)
    const othersVotesLabel = ["OTHERS\n2020", "OTHERS\n2024"]

    //test ======

    const labels2020 = results2020.map((result) => result.party.name)
    const votesData2020 = results2020.map((result) => result.votes)

    // const labels2024 = results2024.map((result) => result.party.name)
    const votesData2024 = results2024.map((result) => result.votes)

    ndcVoteChartDataPresidential.value = {
      labels: ndcVotesLabel,
      datasets:
        [
          {
            label: '',
            data: ndcVotesData2024,
            backgroundColor: [getBackgroundColor(ndc2020And24[0], 0.6), getBackgroundColor(ndc2020And24[1]), 'rgba(0, 0, 0,)'],
            borderColor: [getBackgroundColor(ndc2020And24[0]), getBackgroundColor(ndc2020And24[1]), 'rgba(0, 0, 0,)'],
            borderWidth: 1
          }
        ]
    };

    nppVoteChartDataPresidential.value = {
      labels: nppVotesLabel,
      datasets:
        [
          {
            label: '',
            data: nppVotesData2024,
            backgroundColor: [getBackgroundColor(npp2020And24[0], 0.6), getBackgroundColor(npp2020And24[1]), 'rgba(0, 0, 0,)'],
            borderColor: [getBackgroundColor(npp2020And24[0]), getBackgroundColor(npp2020And24[1]), 'rgba(0, 0, 0,)'],
            borderWidth: 1
          }
        ]
    };

    othersVoteChartDataPresidential.value = {
      labels: othersVotesLabel,
      datasets:
        [
          {
            label: '',
            data: othersVotesData2024,
            backgroundColor: ['rgba(0, 0, 0,0.6)', 'rgba(0, 0, 0,)'],
            borderColor: ['rgba(0, 0, 0,0.6)', 'rgba(0, 0, 0,)'],
            borderWidth: 1
          }
        ]
    };


  };

  const setChartDataParliamentary = () => {
    const presidentialElectionResults2020: ElectionResult[] = mParliamentaryElectionResults2020.value
    // const presidentialElectionResults2024: ElectionResult[] = mParliamentaryElectionResults2024.value

    const sortedParliamentaryResults2020 = accumulateAndSortVotes(presidentialElectionResults2020)
    // const sortedParliamentaryResults2024 = accumulateAndSortVotes(presidentialElectionResults2024)

    const [first2020, second2020] = sortedParliamentaryResults2020
    const other2020 = sortedParliamentaryResults2020.slice(2);
    const otherParties2020: PartyVotes = {
      party: { name: 'Other' },
      votes: other2020.reduce((sum, party) => sum + party.votes, 0),
      votesRatio: other2020.reduce((sum, party) => sum + (party.votesRatio ?? 0), 0)
    }

    // const [first2024, second2024] = sortedParliamentaryResults2024
    // const other2024 = sortedParliamentaryResults2020.slice(2);
    // const otherParties2024: PartyVotes = {
    //   party: { name: 'Other' },
    //   votes: other2024.reduce((sum, party) => sum + party.votes, 0),
    //   votesRatio: other2024.reduce((sum, party) => sum + (party.votesRatio ?? 0), 0)
    // }

    const results2020 = [first2020, second2020, otherParties2020]
    // const results2024 = [first2024, second2024, otherParties2024]

    const labels2020 = results2020.map((result) => result.party.name)
    const votesData2020 = results2020.map((result) => result.votes)

    // const labels2024 = results2024.map((result) => result.party.name)
    // const votesData2024 = results2024.map((result) => result.votes)

    mVoteChartDataParliamentary.value = {
      labels: labels2020,
      datasets:
        [
          // {
          //   label: '',
          //   data: votesData2020,
          //   backgroundColor: getBackgroundColor(results2020, 0.7),
          //   borderColor: getBackgroundColor(results2020),
          //   borderWidth: 1
          // },
          // {
          //   label: '',
          //   data: votesData2024,
          //   backgroundColor: getBackgroundColor(results2024, 0.7),
          //   borderColor: getBackgroundColor(results2024),
          //   borderWidth: 1
          // }
        ]
    };

  };


  const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    mChartOptions.value = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          }
        },
        tooltip: {
          // callbacks: {
          //   label: (context: any) => `${context.dataset.label}: ${context.raw}`,
          // },
          bodyFont: {
            size: 20, // Set the tooltip text size here
            weight: 'bold', // Optional: set font weight
          },
          titleFont: {
            size: 22, // Set title font size (e.g., the label of the data group)
          },
        },
        datalabels: {
          color: 'white',
          font: {
            size: 22,
          },
          formatter: (value: number) => `${value.toLocaleString()}`,
          labels: {
            title: {
              font: {
                size: 22,
                weight: 'bold'
              }
            },

          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };

  }

  function getBackgroundColor(partyVotes: PartyVotes, opacity: number = 1): string {
    const info = partyInfo.find((p) => p.name === partyVotes.party.name)
    const color = hexToRgb(info?.color ?? '')
    return `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${opacity})`
  }

  function accumulateAndSortVotes(
    electionResults: ElectionResult[]
  ): PartyVotes[] {
    const accumulatedPartyVotes: PartyVotes[] = electionResults.reduce(
      (acc: PartyVotes[], result) => {
        result.partyVotes.forEach((partyVote) => {
          if (partyVote.party.name) {
            // Check if the party already exists in the accumulator
            const existingPartyVote = acc.find((pv) => pv.party.name === partyVote.party.name)
            if (existingPartyVote) {
              existingPartyVote.votes += partyVote.votes // Accumulate votes
            } else {
              // If not, add a new entry for the party
              acc.push({
                party: partyVote.party,
                candidate: partyVote.candidate,
                votes: partyVote.votes
              })
            }
          }
        })
        return acc
      },
      []
    )

    // Step 2: Sort the accumulated votes by number of votes in descending order
    const sortedVotes = accumulatedPartyVotes.sort((a, b) => b.votes - a.votes)

    // Step 3: Calculate total votes
    const totalVotes = sortedVotes.reduce((acc, partyVote) => acc + partyVote.votes, 0)

    // Step 4: Calculate the vote ratio for each party
    return accumulatedPartyVotes.map((partyVote) => ({
      ...partyVote,
      votesRatio: totalVotes > 0 ? partyVote.votes / totalVotes : 0
    }))
  }


  const getPresidentialElectionResults = async () => {
    const [result2020, result2024] = await Promise.all([
      presidentialElectionService.getConstituencyElectionResults(),
      presidentialElectionService.getConstituencyElectionResultsRemote(),
    ])

    result2020.fold(
      (value) => {
        mPresidentialElectionResults2020.value = value as ElectionResult[]
        extractResultData(mParliamentaryElectionResults2020.value)

      },
      (error) => console.error(error)
    )
    result2024.fold(
      (value) => {
        mPresidentialElectionResults2024.value = value as ElectionResult[]
        extractResultData(mPresidentialElectionResults2024.value)
        setChartDataPresidential()
      },
      (error) => console.error(error)
    )
  }


  const getParliamentaryElectionResults = async () => {
    const [result2020, result2024] = await Promise.all([
      parliamentaryElectionService.getConstituencyElectionResults(),
      parliamentaryElectionService.getConstituencyElectionResults(),
    ])

    result2020.fold(
      (value) => {
        mParliamentaryElectionResults2020.value = value as ElectionResult[]
        extractResultData(mParliamentaryElectionResults2020.value)
        setChartDataParliamentary()
      },
      (error) => console.error(error)
    )
    // result2024.fold(
    //   (value) => {
    //     mParliamentaryElectionResults2024.value = value as ElectionResult[]
    //   },
    //   (error) => console.error(error)
    // )
  }

  function extractResultData(results?: ElectionResult[] | null) {
    mRegions.value = extractRegions(results)
    mConstituencies.value = extractConstituencies(results)
  }

  function extractRegions(results?: ElectionResult[] | null): string[] {
    if (results) {
      const regions = results
        .map((result) => result.region)
        .filter((region) => region !== undefined) // Filter out undefined regions
      return Array.from(new Set(regions))
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort the regions by name
        .map((region) => region) // Remove duplicates with incremental id
    }
    return []
  }

  function extractConstituencies(results?: ElectionResult[] | null): string[] {
    if (results) {
      const constituencies = results
        .map((result) => result.constituency)
        .filter((constituency) => constituency !== undefined)

      return Array.from(new Set(constituencies))
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort by constituency name
        .map((constituency) => constituency)
    }
    return []
  }

  return {
    regions: mRegions,
    constituencies: mConstituencies,
    selectedRegions: mSelectedRegions,
    selectedConstituencies: mSelectedConstituencies,
    chartOptions: mChartOptions,
    voteChartDataPresidential: mVoteChartDataPresidential,
    voteChartDataParliamentary: mVoteChartDataParliamentary,
    getPresidentialElectionResults,
    getParliamentaryElectionResults,
    setChartOptions,
    startInterval,
    stopInterval,
    nppVoteChartDataPresidential,
    ndcVoteChartDataPresidential,
    othersVoteChartDataPresidential
  }
})
