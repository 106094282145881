<script setup lang="ts">
import { useElectionSlipCreateOrEditFormStore } from '../stores/election-create-or-edit-form.store'

const createOrEditFormStore = useElectionSlipCreateOrEditFormStore()
</script>

<template>
  <Dialog v-model:visible="createOrEditFormStore.showElectionSlipDialog" :style="{ width: '600px' }"
    header="Slip Details" :modal="true" class="p-fluid">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-5">
        <label for="region">Region</label>
        <InputText id="region" type="text" v-bind="createOrEditFormStore.region"
          v-model="createOrEditFormStore.electionSlip.region" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.region }" />
        <small id="region-help" class="p-error">
          {{ createOrEditFormStore.errors.region }}
        </small>
      </div>
      <div class="field col-12 md:col-7">
        <label for="constituency">Constituency</label>
        <InputText id="constituency" type="text" v-bind="createOrEditFormStore.constituency"
          v-model="createOrEditFormStore.electionSlip.constituency" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.constituency }" />
        <small id="constituency-help" class="p-error">
          {{ createOrEditFormStore.errors.constituency }}
        </small>
      </div>

      <div class="field col-12 md:col-5">
        <label for="pollingStationCode">Polling Center Code</label>
        <InputText id="pollingStationCode" type="text" v-bind="createOrEditFormStore.pollingStationCode"
          v-model="createOrEditFormStore.electionSlip.pollingStationCode" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.pollingStationCode }" />
        <small id="pollingStationCode-help" class="p-error">
          {{ createOrEditFormStore.errors.pollingStationCode }}
        </small>
      </div>
      <div class="field col-12 md:col-7">
        <label for="pollingStation">Polling Center Name</label>
        <InputText id="pollingStation" type="text" v-bind="createOrEditFormStore.pollingStation"
          v-model="createOrEditFormStore.electionSlip.pollingStation" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.pollingStation }" />
        <small id="pollingStation-help" class="p-error">
          {{ createOrEditFormStore.errors.pollingStation }}
        </small>
      </div>
      <div class="field col-12 md:col-7">
        <label for="pinkSheetStampStatus">pinkSheetStampStatus</label>
        <InputText id="pinkSheetStampStatus" type="text" v-bind="createOrEditFormStore.pinkSheetStampStatus"
          v-model="createOrEditFormStore.electionSlip.pinkSheetStampStatus" required="true"
          :class="{ 'p-invalid': createOrEditFormStore.errors.pinkSheetStampStatus }" />
        <small id="pinkSheetStampStatus-help" class="p-error">
          {{ createOrEditFormStore.errors.pinkSheetStampStatus }}
        </small>
      </div>
      <div class="card flex justify-center">
        <Image :src="createOrEditFormStore.electionSlip.imageUrl" alt="Image" width="250" preview />
      </div>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text"
        @click="createOrEditFormStore.showElectionSlipDialog = false" />
    </template>
  </Dialog>
</template>
