<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useElectionResultBarStore } from '../stores/election-result-bar.store'

import MapHeader from '../components/MapHeader.vue'

const indexStore = useElectionResultBarStore()

const dataTableContainer = ref<HTMLElement | null>(null)

let observer: IntersectionObserver | null = null;

const observerCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            indexStore.startInterval(); // Start API polling
        } else {
            indexStore.stopInterval(); // Stop API polling
        }
    });
};

onMounted(async () => {
    indexStore.setChartOptions()
    indexStore.getPresidentialElectionResults()

    observer = new IntersectionObserver(observerCallback);
    if (dataTableContainer.value) {
        observer.observe(dataTableContainer.value);
    }
})

onUnmounted(() => {
    if (observer && dataTableContainer.value) {
        observer.unobserve(dataTableContainer.value);
    }
    indexStore.stopInterval(); // Ensure interval stops on unmount
    observer = null;
});


</script>

<template>
    <div ref="dataTableContainer" class="card">
        <MapHeader title="REPRESENTATIVE POLLING STATION ANALYSIS" />
        <div class="col-12">
            <MultiSelect v-model="indexStore.selectedConstituencies" display="chip" :options="indexStore.constituencies"
                filter placeholder="Select Constituency" :maxSelectedLabels="3" class="w-full md:w-18rem">
                <template #footer>
                    <div class="py-2 px-3">
                        <b>{{
                            indexStore.selectedRegions ?
                                indexStore.selectedRegions.length : 0
                        }}</b>
                        item{{
                            (indexStore.selectedRegions ?
                                indexStore.selectedRegions.length : 0) >
                                1
                                ? 's'
                                : ''
                        }}
                        selected.
                    </div>
                </template>
            </MultiSelect>
            <div class="p-fluid grid">
                <div class="col-12 lg:col-8 xl:col-4">
                    <Chart type="bar" :data="indexStore.ndcVoteChartDataPresidential" :options="indexStore.chartOptions"
                        class="h-30rem" />
                </div>
                <div class="col-12 lg:col-8 xl:col-4">
                    <Chart type="bar" :data="indexStore.nppVoteChartDataPresidential" :options="indexStore.chartOptions"
                        class="h-30rem" />
                </div>
                <div class="col-12 lg:col-8 xl:col-4">
                    <Chart type="bar" :data="indexStore.othersVoteChartDataPresidential"
                        :options="indexStore.chartOptions" class="h-30rem" />
                </div>
            </div>
        </div>


    </div>
</template>