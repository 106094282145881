<script setup lang="ts">
import { ref, computed } from 'vue';
import ElectoralCard from '@/modules/election/components/ElectoralCard.vue'
import type { ElectionSummary } from '@/modules/election/models/election-summary.model';

interface Props {
    previousYear: number | string;
    currentYear: number | string;
    previousYearRegisteredVoters: number;
    currentYearRegisteredVoters: number;
    percentageChange: number;
    absoluteChange: number;
    summary: ElectionSummary | null;
}

const props = defineProps<Props>()

const isResultVisible = ref<boolean>(false)

function toggleResult() {
    isResultVisible.value = !isResultVisible.value
}

const formatNumber = (num: number): string => {
    return new Intl.NumberFormat('en-US').format(Math.abs(num));
};
const formatPercentage = (value: number): string => {
    return `${(value).toFixed(2)}`
}
const isIncrease = computed(() => props.percentageChange > 0);
const changeType = computed(() => isIncrease.value ? 'Increase' : 'Decrease');
const changeClass = computed(() => isIncrease.value ? 'increase' : 'decrease');


const firstLeadingPartyStyle = computed(() => ({
    color: props.summary?.firstLeadingParty.color as string
}))

const secondLeadingPartyStyle = computed(() => ({
    color: props.summary?.secondLeadingParty.color as string
}))

const otherLeadingPartyStyle = computed(() => ({
    color: '#DAA520'
}))

const firstLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.firstLeadingParty.percentage}%`,
    backgroundColor: props.summary?.firstLeadingParty.color as string
}))

const secondLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.secondLeadingParty.percentage}%`,
    backgroundColor: props.summary?.secondLeadingParty.color as string
}))

const otherLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.otherLeadingParty.percentage}%`,
    backgroundColor: props.summary?.otherLeadingParty.color,
    left: `${props.summary?.firstLeadingParty.percentage}%`
}))

</script>

<template>
    <div class="voter-stats">
        <h1 class="stats-title">Registered Voters</h1>

        <div class="stats-grid">
            <!-- Previous Year Stats -->
            <div class="stats-card stats-card-previous-year">
                <h2 class="year-label">{{ previousYear }}</h2>
                <p class="number">
                    {{ formatNumber(previousYearRegisteredVoters) }}
                </p>
            </div>

            <!-- Current Year Stats -->
            <div class="stats-card stats-card-current-year">
                <h2 class="year-label">{{ currentYear }}</h2>
                <p class="number">
                    {{ formatNumber(currentYearRegisteredVoters) }}
                </p>
            </div>
        </div>

        <!-- Change Stats -->
        <div class="stats-card" :class="['stats-card-change', changeClass]">
            <p class="change-title">Registered Voters {{ changeType }}</p>
            <div class="change-stats">
                <div class="percentage">
                    <span class="arrow" :class="changeClass"></span>
                    <span class="number">
                        {{ formatPercentage(percentageChange) }}%
                    </span>
                </div>
                <div class="total-change">
                    <p class="number">
                        {{ isIncrease ? '+' : '-' }}{{ formatNumber(absoluteChange) }}
                    </p>
                    <!-- <p class="label">Total {{ changeType }} in Registrations</p> -->
                </div>
            </div>
        </div>
        <div v-if="summary">
            <h1 class="results-title" @click="toggleResult">2020 RESULTS</h1>

            <div class="results-bar" v-if="isResultVisible">

                <ElectoralCard :style="firstLeadingPartyStyle" :name="props.summary?.firstLeadingParty.name"
                    party="Democratic" :electoral-votes="props.summary?.firstLeadingParty.constituencyWon"
                    :popular-votes="props.summary?.firstLeadingParty.votes"
                    :image-url="props.summary?.firstLeadingParty.image"
                    :color="props.summary?.firstLeadingParty.color" />

                <!-- Results Section -->
                <div class="results-section">
                    <div class="bar">
                        <div class="first-leading-party-bar" :style="firstLeadingPartyBarStyle">
                            <span class="percentage-label">{{ props.summary?.firstLeadingParty.percentage }}%</span>
                        </div>
                        <div class="other-leading-party-bar" :style="otherLeadingPartyBarStyle"></div>
                        <div class="second-leading-party-bar" :style="secondLeadingPartyBarStyle">
                            <span class="percentage-label">{{ props.summary?.secondLeadingParty.percentage }}%</span>
                        </div>
                    </div>
                </div>

                <ElectoralCard :style="secondLeadingPartyStyle" :name="props.summary?.secondLeadingParty.name"
                    party="Democratic" :electoral-votes="props.summary?.secondLeadingParty.constituencyWon"
                    :popular-votes="props.summary?.secondLeadingParty.votes"
                    :image-url="props.summary?.secondLeadingParty.image"
                    :color="props.summary?.secondLeadingParty.color" />

            </div>
        </div>
    </div>
</template>

<style scoped>
.voter-stats {
    margin: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stats-title {
    font-size: 2rem;
    font-weight: 700;
    color: #636363;
    margin-bottom: 2rem;
}

.results-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #636363;
    margin-top: 1rem;
}

.stats-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .stats-grid {
        grid-template-columns: 1fr 1fr;
    }
}

.stats-card {
    padding: 1.5rem;
    border-radius: 0.5rem;
}

.stats-card-previous-year {
    background-color: #f9fafb;
}

.stats-card-current-year {
    background-color: #eff6ff;
}

.stats-card-change {
    transition: background-color 0.3s ease;
}

.stats-card-change.increase {
    background-color: #ecfdf5;
}

.stats-card-change.decrease {
    background-color: #fef2f2;
}

.year-label {
    font-size: 1rem;
    color: #4b5563;
    margin-bottom: 0.5rem;
}

.number {
    font-size: 2rem;
    font-weight: 700;
}

.stats-card-previous-year .number {
    color: #1f2937;
}

.stats-card-current-year .number {
    color: #1e40af;
}

.change-title {
    font-size: 1.1rem;
    color: #4b5563;
    margin-bottom: 1rem;
}

.change-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.percentage {
    display: flex;
    align-items: center;
}

.arrow {
    width: 0;
    height: 0;
    margin-right: 0.5rem;
}

.arrow.increase {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 16px solid #059669;
}

.arrow.decrease {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 16px solid #dc2626;
}

.increase .number {
    color: #059669;
}

.decrease .number {
    color: #dc2626;
}

.total-change {
    text-align: right;
}

.total-change .label {
    font-size: 0.875rem;
    color: #4b5563;
    margin-top: 0.25rem;
}


.election-results {
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.title {
    font-size: 4.8rem;
    font-weight: bold;
    text-align: center;
    margin: 25px 0px 20px 0px;
    /* margin-top: 0; */
    color: #636363;
    letter-spacing: 1px;
}

.results-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px;
}

.candidates {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
}

.candidate {
    display: flex;
    align-items: center;
    width: 45%;
    min-width: 150px;
}

.candidate-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 13px;
}

.candidate-info {
    display: flex;
    flex-direction: column;
}

.electoral-votes {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 15px;
    letter-spacing: 1px;
}

.candidate-name {
    font-weight: bold;
    font-size: 1rem;
}

.vote-percentage,
.vote-count {
    font-size: 2.1rem;
    font-weight: bold;
}

.vote-percentage {
    margin-bottom: 15px;
    letter-spacing: 1px;
}


.vote-count {
    letter-spacing: 1px;
}

.results-section {
    width: 100%;
    margin-top: 10px;
}

.bar {
    height: 35px;
    background-color: #f0f0f0;
    border-radius: 0.5rem;
    position: relative;
    overflow: hidden;
    margin: 15px 0;
}

.first-leading-party-bar,
.second-leading-party-bar,
.other-leading-party-bar {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
}

.first-leading-party-bar {
    left: 0;
}

.second-leading-party-bar {
    right: 0;
}

.middle-line {
    position: absolute;
    top: 0;
    left: 50%;
    width: 3px;
    height: 100%;
    background-color: #000;
}

.divider-line {
    width: 8px;
    height: 100%;
    background-color: #EEE;
}

.pointer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 13px solid #000;
}

.win-marker {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    white-space: nowrap;
    margin-top: 10px;
}

.win-margin {
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    margin-bottom: 30px;
}

.second-leading-party {
    flex-direction: row-reverse;
    text-align: right;
}

.others {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0;
}

.others .candidate-info {
    display: inline-block;
}

.percentage-label {
    padding: 0 5px;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .title {
        font-size: 2rem;
    }

    .candidate {
        width: 100%;
    }

    .candidate-image {
        width: 70px;
        height: 70px;
        margin-right: 10px;
    }

    .electoral-votes {
        font-size: 1.5rem;
    }

    .vote-percentage,
    .vote-count {
        font-size: 1rem;
        margin-bottom: 120px;
    }

    .bar {
        height: 30px;
    }

    .pointer {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #000;
    }

    .win-marker {
        font-size: 0.8rem;
        margin-top: 8px;
    }

    .win-margin {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 1.5rem;
    }

    .candidate-image {
        width: 50px;
        height: 50px;
    }

    .electoral-votes {
        font-size: 1.2rem;
    }

    .vote-percentage,
    .vote-count {
        font-size: 0.9rem;
    }

    .bar {
        height: 20px;
    }

    .pointer {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #000;
    }

    .win-marker {
        font-size: 0.7rem;
    }

    .win-margin {
        font-size: 0.9rem;
    }
}
</style>