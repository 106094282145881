import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useForm } from 'vee-validate'
import { object, string, number } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useBaseStore } from '@/modules/core/stores/base.store'
import { emptySlip, type PresidentialSlip } from '../models/slip.model'

// import { emptyElectionResult, type ElectionResult } from '../models/polling-center.model'

// const baseStore = useBaseStore()
// const apiService = new ElectionResultService()

const schema = toTypedSchema(
  object({
    region: string().required().min(4).label('region'),
    constituency: string().required().min(4).label('constituency'),
    pollingStationCode: string().required().min(4).label('pollingStationCode'),
    pollingStation: string().required().min(4).label('pollingStation'),
    manualVerification: string().required().label('manualVerification'),
    pinkSheetStampStatus: string().required().label('pinkSheetStampStatus'),
    voterName: string().required().label('voterName'),
    reason: string().nullable().label('reason'),
  })
)

export const useElectionSlipCreateOrEditFormStore = defineStore(
  'electionCreateOrEditForm',
  () => {
    const { defineComponentBinds, setFieldValue, handleSubmit, errors } = useForm({
      validationSchema: schema
    })

    const region = defineComponentBinds('region')
    const constituency = defineComponentBinds('constituency')
    const pollingStationCode = defineComponentBinds('pollingStationCode')
    const pollingStation = defineComponentBinds('pollingStation')
    const manualVerification = defineComponentBinds('manualVerification')
    const pinkSheetStampStatus = defineComponentBinds('pinkSheetStampStatus')
    const voterName = defineComponentBinds('voterName')
    const reason = defineComponentBinds('reason')

    const showElectionSlipDialog = ref(false)
    const isSubmitting = ref(false)


    const electionSlip = ref<PresidentialSlip>(emptySlip)
    const isEditing = ref<boolean>(false)

    const createElectionSlip = () => {
      isEditing.value = false
      electionSlip.value = emptySlip
      showElectionSlipDialog.value = true
    }

    const editElectionSlip = (editElectionSlip: PresidentialSlip) => {
      isEditing.value = true
      electionSlip.value = editElectionSlip

      // setFieldValue('region', editElectionResult.region)
      // setFieldValue('constituency', editElectionResult.constituency)
      // setFieldValue('electoralArea', editElectionResult.electoralArea)
      // setFieldValue('code', editElectionResult.code)
      // setFieldValue('name', editElectionResult.name)
      // setFieldValue('latitude', editElectionResult.latitude)
      // setFieldValue('longitude', editElectionResult.longitude)
      // setFieldValue('agentId', editElectionResult.agentId)
      // setFieldValue('agentName', editElectionResult.agentName)

      showElectionSlipDialog.value = true
    }



    const onSaveElectionResult = handleSubmit((values) => {
      saveElectionResult();
    })

    const saveElectionResult = async () => {
      isSubmitting.value = true;

      // const result = await apiService.postOrPatchElectionResult(election.value, !isEditing.value ? 'post' : 'patch');

      // result.fold(
      //   (value) => {
      //     isSubmitting.value = false;
      //     showElectionResultDialog.value = false
      //     baseStore.showToast({
      //       severity: 'success',
      //       summary: ElectionResultUiConstants.TOAST_SUMMARY,
      //       detail: `ElectionResult with Id ${value} Saved`,
      //       life: 3000
      //     });
      //     isEditing.value = false;
      //     useElectionResultIndexStore().getElectionResults()
      //   },
      //   (error) => {
      //     isSubmitting.value = false;
      //     baseStore.showToast({
      //       severity: 'error',
      //       summary: ElectionResultUiConstants.TOAST_SUMMARY,
      //       detail: error.message,
      //       life: 3000
      //     });
      //     isEditing.value = false;
      //   },
      // );
    };

    return {
      errors,
      region,
      constituency,
      pollingStationCode,
      pollingStation,
      manualVerification,
      pinkSheetStampStatus,
      voterName,
      reason,
      electionSlip,
      showElectionSlipDialog,
      isSubmitting,
      createElectionSlip,
      editElectionSlip,
      onSaveElectionResult
    }
  }
)
