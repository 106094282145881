import type { Meta } from "@/modules/core/models/meta.model"

export interface Slips {
    data: PresidentialSlip[] | PresidentialSlip[]
    meta: Meta
}

export interface Slip {
    id: number
    serialNumber: string
    pollingStationCode: string
    pollingStation: string
    constituency: string
    region: string
    totalBallotsIssuedA1: string;
    registerVotersB1: string;
    ballotsIssuedVotersC1: string;
    rejectedBallotD1: string;
    spoiltBallotC3: string;
    totalVotesCastRecordedSnc: string;
    totalValidBallotsRecordedSna: string;
    biometricVerificationDevice: string;
    manualVerification: string;
    pinkSheetStampStatus: string;
    voterName: string;
    date: string
    time: string
    duplicate_status: string
    reason: any
    sent_at: string
    unique_id: string
    agent: string
    notes: any
    isVerified: any
    imageUrl: string
    createdAt: Date
    link: string
}

export interface PresidentialSlip extends Slip {
    npp: string
    ndc: string
    gum: string
    cpp: string
    gfp: string
    gcpp: string
    apc: string
    lpg: string
    ndp: string
    kofiKoranteng: string
    georgeAdu: string
    nanaBediako: string
    alanKyeremanten: string
    independent: string
}

export interface ParliamentarySlip extends Slip {
    candidate1Name: string;
    candidate1Score: string;
    candidate2Name: string;
    candidate2Score: string;
    candidate3Name?: string | null;
    candidates3Score?: string | null;
    candidate4Name?: string | null;
    candidate4Score?: string | null;
    candidate5Name?: string | null;
    candidate5Score?: string | null;
    candidate6Name?: string | null;
    candidate6Score?: string | null;
    candidate7Name?: string | null;
    candidate7Score?: string | null;
    candidate8Name?: string | null;
    candidate8Score?: string | null;
    candidate9Name?: string | null;
    candidate9Score?: string | null;
    candidate10Name?: string | null;
    candidate10Score?: string | null;
    candidate11Name?: string | null;
    candidate11Score?: string | null;
    candidate12Name?: string | null;
    candidate12Score?: string | null;
}


export const emptySlip: PresidentialSlip = {
    id: 0,
    region: '',
    constituency: ''
} as PresidentialSlip
