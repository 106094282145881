import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { FeatureCollection } from 'geojson'


import MapService from '@/modules/map/services/map-api.service'
import type Region from '@/modules/data/models/region.model'
import type Constituency from '@/modules/data/models/constituency.model'


import GroupedDataApiService from '@/modules/data/services/grouped-data-api.service'
import type GroupedData from '@/modules/data/models/grouped_data.model'

const mapService = new MapService()
const groupedDataService = new GroupedDataApiService()

export const useDataStore = defineStore('data', () => {

  const mSixteenRegionsGeoJson = ref<FeatureCollection | null>(null)
  const mTenRegionsGeoJson = ref<FeatureCollection | null>(null)
  const mConstituencyGeoJson = ref<FeatureCollection | null>(null)

  const mSixteenRegions = ref<Region[]>([])
  const mTenRegions = ref<Region[]>([])

  const mConstituencies = ref<Constituency[]>([])

  const mGroupedData = ref<GroupedData[]>([])

  const getShapeFiles = async () => {
    const [resultRegions, resultRegionsOld, resultConstituency] = await Promise.all([
      mapService.getRegionShapeFile(),
      mapService.getRegionOldShapeFile(),
      mapService.getConstituencyShapeFile()
    ])

    resultRegions.fold(
      (value) => {
        mSixteenRegionsGeoJson.value = value as FeatureCollection
        console.log('region shape file set')
        const regions = extractRegions(mSixteenRegionsGeoJson.value)
        mSixteenRegions.value = regions
      },
      (error) => console.log(error)
    )
    resultRegionsOld.fold(
      (value) => {
        mTenRegionsGeoJson.value = value as FeatureCollection
        console.log('region old shape file set')
        const regions = extractRegions(mTenRegionsGeoJson.value)
        mTenRegions.value = regions
      },
      (error) => console.log(error)
    )

    resultConstituency.fold(
      (value) => {
        mConstituencyGeoJson.value = value as FeatureCollection

        console.log('constituency shape file set')
        const constituencies = extractConstituencies(mConstituencyGeoJson.value)
        mConstituencies.value = constituencies
      },
      (error) => console.log(error)
    )
  }

  const getGroupedData = async () => {
    const result = await groupedDataService.getGroupedData()

    result.fold(
      (value) => {
        mGroupedData.value = value as GroupedData[]
        console.log('grouped data set')
      },
      (error) => console.log(error)
    )
  }

  function extractRegions(geoJson?: FeatureCollection | null): Region[] {
    if (geoJson) {
      const regions = geoJson.features
        .map((feature: any) => [
          feature.properties?.region.trim(),
          feature.properties?.const_num
        ])
        .filter((region: any) => region !== undefined) // Filter out undefined regions
      return Array.from(new Set(regions))
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort the regions by name
        .map(([name, constituencyNum], index) => ({ id: index + 1, name: name, constituencyCount: constituencyNum })) // Remove duplicates with incremental id
    }
    return []
  }

  function extractConstituencies(geoJson?: FeatureCollection | null): Constituency[] {
    if (geoJson) {
      const constituencies = geoJson.features
        .map((feature: any) => [
          feature.properties?.constituen.trim(),
          feature.properties?.region.trim(),
          feature.properties?.ten_regions.trim()
        ])
        .filter((constituency: any) => constituency !== undefined)

      return Array.from(new Set(constituencies))
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort by constituency name
        .map(([name, region, regionOld], index: any) => ({ id: index + 1, name, region, regionOld }))
    }
    return []
  }

  return {
    sixteenRegionsGeoJson: mSixteenRegionsGeoJson,
    tenRegionsGeoJson: mTenRegionsGeoJson,
    constituencyGeoJson: mConstituencyGeoJson,
    sixteenRegions: mSixteenRegions,
    tenRegions: mTenRegions,
    constituencies: mConstituencies,
    groupedData: mGroupedData,
    getShapeFiles,
    getGroupedData
  }
})
