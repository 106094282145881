import { ref } from 'vue'
import { defineStore } from 'pinia'

import type { PollingStation } from '../models/polling-station.model'

import PollingCenterApiService from '../services/polling-center-api.service'
import { sanitizeAndLowercaseString } from '@/modules/core/utils/string.util'


const pollingStationService = new PollingCenterApiService()

export const usePollingStationStore = defineStore('pollingStation', () => {

    const mPollingStations = ref<PollingStation[]>([])

    const getPollingStations = async () => {

        const result = await pollingStationService.getPollingStations()

        result.fold(
            (value) => {
                mPollingStations.value = value as PollingStation[]
            },
            (error) => console.log(error)
        )
    }

    const getRegionPollingStationsCount = (regions: string[]) => {
        const filteredStations = mPollingStations.value.filter(station =>
            regions.some(region => sanitizeAndLowercaseString(region) === sanitizeAndLowercaseString(station.region))
        )
        return filteredStations
    }

    const getConstituencyPollingStationsCount = (constituencies: string[]) => {
        const filteredStations = mPollingStations.value.filter(station =>
            constituencies.some(constituency => sanitizeAndLowercaseString(constituency) === sanitizeAndLowercaseString(station.constituency))
        )
        return filteredStations
    }


    return {
        pollingStations: mPollingStations,
        getPollingStations,
        getRegionPollingStationsCount,
        getConstituencyPollingStationsCount
    }
})