<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import 'leaflet/dist/leaflet.css'

import '@/assets/styles/map/styles.css';
import { LayerLevel } from '../enums/layer-level.enum'
import { VoterType } from '../../voter/enums/voter-type.enum'

import { useDataStore } from '../../data/stores/data.store'
import { useVoterStore } from '../../voter/stores/voter.store'
import { useMapUiStore } from '../stores/ui.store'
import { useRegisteredVoterMapStore } from '../stores/registered-voter-map.store'
import { useElectionStore } from '../../election/stores/election-map.store'

import VoterRegistrationStats from '@/modules/voter/components/RegisteredVoterStats.vue'

import MapHeader from '../components/MapHeader.vue'
import Legend from '../components/Legend.vue'

const mapContainer = ref<HTMLElement | null>(null)
const dataStore = useDataStore()
const voterStore = useVoterStore()
const mapUiStore = useMapUiStore()
const voterMapStore = useRegisteredVoterMapStore()
const electionStore = useElectionStore()

const showRegionNewButton = computed(() => voterMapStore.currentLevel !== LayerLevel.REGION_SIXTEEN)
const showRegionOldButton = computed(() => voterMapStore.currentLevel !== LayerLevel.REGION_TEN)

const showConstituencyButton = computed(
  () =>
    voterMapStore.currentLevel !== LayerLevel.CONSTITUENCY &&
    voterMapStore.currentLevel !== LayerLevel.REGION_SIXTEEN_CONSTITUENCY
)

function resetStore() {
  mapUiStore.resetStore()
  voterMapStore.resetStore()
}


onMounted(async () => {
  await Promise.all([
    dataStore.getShapeFiles(),
    dataStore.getGroupedData(),
    voterStore.getVoterCounts(),
    electionStore.getPresidentialElections(),
  ]);
  if (mapContainer.value) {
    voterMapStore.initializeMap(mapContainer.value)
    voterMapStore.initializeLayers()
  }
  electionStore.setPresidentialElection('2020')
})

</script>

<template>
  <div class="parent">
    <div class="registered-voters-container">

      <MapHeader title="REGISTERED VOTERS HEAT MAP" />

      <div class="registered-voters">
        <div class="map-statistics">
          <div class="map-statistics-container">
            <div class="map-statistics-content">
              <VoterRegistrationStats previousYear="2020" currentYear="2024"
                :previousYearRegisteredVoters="voterStore.registeredVotersSummary().value?.previousYearRegisteredVoters"
                :currentYearRegisteredVoters="voterStore.registeredVotersSummary().value?.currentYearRegisteredVoters"
                :percentageChange="voterStore.registeredVotersSummary().value?.percentage"
                :absoluteChange="voterStore.registeredVotersSummary().value?.net"
                :summary="electionStore.presidentialElectionResultS" />
            </div>
            <div class="map-statistics-legend">
              <Button class="control-button" @click="voterMapStore.toggleLegend" text>
                {{ voterMapStore.isLegendVisible ? 'Hide Legend' : 'Show Legend' }}
              </Button>
              <Legend v-if="voterMapStore.isLegendVisible" :items="voterMapStore.legendItems"
                :title="voterMapStore.legendTitle" :horizontal="true" />
            </div>
          </div>
        </div>

        <div class="map-content">
          <div class="map-controls">
            <Dropdown v-model="voterMapStore.selectedVoterType" :options="voterMapStore.voterTypes"
              placeholder="Select Type" checkmark :highlightOnSelect="true" />
            <Dropdown v-if="voterMapStore.selectedVoterType === VoterType.RATIO" v-model="voterMapStore.selectedYear"
              :options="voterMapStore.year" placeholder="Select Year" checkmark :highlightOnSelect="true" />
            <MultiSelect v-if="voterMapStore.currentLevel === LayerLevel.REGION_SIXTEEN_CONSTITUENCY"
              v-model="voterMapStore.selectedSixteenRegions" :options="dataStore.sixteenRegions" filter
              optionLabel="name" placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    voterMapStore.selectedSixteenRegions ? voterMapStore.selectedSixteenRegions.length
                      : 0
                  }}</b>
                  item{{
                    (voterMapStore.selectedSixteenRegions ? voterMapStore.selectedSixteenRegions.length
                      : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="voterMapStore.currentLevel === LayerLevel.REGION_TEN_CONSTITUENCY"
              v-model="voterMapStore.selectedTenRegions" :options="dataStore.tenRegions" filter optionLabel="name"
              placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    voterMapStore.selectedTenRegions ? voterMapStore.selectedTenRegions.length : 0
                  }}</b>
                  item{{
                    (voterMapStore.selectedTenRegions ? voterMapStore.selectedTenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="voterMapStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="voterMapStore.selectedConstituencies" :options="dataStore.constituencies" filter
              optionLabel="name" placeholder="Select Constituencies" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    voterMapStore.selectedConstituencies
                      ? voterMapStore.selectedConstituencies.length
                      : 0
                  }}</b>
                  item{{
                    (voterMapStore.selectedConstituencies
                      ? voterMapStore.selectedConstituencies.length
                      : 0) > 1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <Dropdown v-if="voterMapStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="voterMapStore.selectedGroupedData" :options="dataStore.groupedData" optionLabel="name"
              placeholder="Select Grouped Constituencies" checkmark :highlightOnSelect="true" />

            <Button class="control-button" v-if="showRegionNewButton"
              @click="voterMapStore.setActiveLayer(LayerLevel.REGION_SIXTEEN)" text>
              16 Regions
            </Button>
            <Button class="control-button" v-if="showRegionOldButton"
              @click="voterMapStore.setActiveLayer(LayerLevel.REGION_TEN)" text>
              10 Regions
            </Button>
            <Button class="control-button" v-if="showConstituencyButton"
              @click="voterMapStore.setActiveLayer(LayerLevel.CONSTITUENCY)" text>
              Constituencies
            </Button>

            <Button class="control-button" @click="voterMapStore.fitBounds" text>Resize Map</Button>
            <Button class="control-button" @click="resetStore" text>Reset</Button>
          </div>

          <div ref="mapContainer" class="map-container"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.registered-voters-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.electoral-card {
  display: flex;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 0.75rem;
  padding: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  max-width: 56rem;
  margin: 1rem auto;
  overflow: hidden;
  position: relative;
}

.electoral-votes {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.work-in-progress {
  font-size: 1.1rem;
}
</style>
