import { defineStore } from 'pinia'
import { ref, watch, computed } from 'vue'

import type Election from '../models/election.model'
import type PartyVotes from '../models/party_votes.model'
import type LeadingParty from '../models/leading_party.model'
import type ElectionResult from '../models/election-result.model'
import type { ElectionSummary } from '../models/election-summary.model'
import type AggregatedElectionResult from '../models/aggregated_election_result.model'

import { ElectionType } from '../enums/election-type.enum'
import { RegionType } from '@/modules/data/enums/region-type.enum'
import { LayerLevel } from '@/modules/map/enums/layer-level.enum'

import PresidentialElectionService from '../services/presidential-election.service'
import ParliamentaryElectionService from '../services/parliamentary-election.service'

import { trimAndLowercase } from '@/modules/core/utils/string.util'

import { usePresidentialMapStore } from '@/modules/map/stores/presidential-map.store'
import { useParliamentaryMapStore } from '@/modules/map/stores/parliamentary-map.store'
import { useRegisteredVoterMapStore } from '@/modules/map/stores/registered-voter-map.store'

import { unknown } from '@/modules/map/utils/map-layer-styles'
import partyInfo from '@/assets/election/party_info.json'

const presidentialElectionService = new PresidentialElectionService()
const parliamentaryElectionService = new ParliamentaryElectionService()

export const useElectionStore = defineStore('electionMap', () => {
  const presidentialMapStore = usePresidentialMapStore()
  const parliamentaryMapStore = useParliamentaryMapStore()
  const registeredVotersMapStore = useRegisteredVoterMapStore()

  const mPresidentialElectionResults = ref<ElectionResult[]>([])
  const mPresidentialElections = ref<Election[]>([])
  const mSelectedPresidentialElection = ref<Election>({
    year: '2012',
    results: []
  } as Election)

  const mParliamentaryElections = ref<Election[]>([])
  const mSelectedParliamentaryElection = ref<Election>({
    year: '2012',
    results: []
  } as Election)


  function resetStore() {
    mSelectedPresidentialElection.value = mPresidentialElections.value[0] as Election
    mSelectedParliamentaryElection.value = mParliamentaryElections.value[0] as Election
  }

  watch(mSelectedPresidentialElection, (selectedElection) => {
    if (selectedElection) {
      presidentialMapStore.updateLayers()
    }
  })

  watch(mSelectedParliamentaryElection, (selectedElection) => {
    if (selectedElection) {
      parliamentaryMapStore.updateLayers()
    }
  })

  const calculateVotePercentage = (partyVotes: number, result: PartyVotes[]): string => {
    const [firstLeadingParty, secondLeadingParty] = result
    const firstLeadingPartyVotes: number = firstLeadingParty.votes
    const secondLeadingPartyVotes: number = secondLeadingParty.votes

    const totalVotes = firstLeadingPartyVotes + secondLeadingPartyVotes

    return formatPercentage(partyVotes / totalVotes)
  }


  function calculateWinMargin(results: PartyVotes[]): { margin: number; marginPercentage: number } {
    if (results.length < 2) return { margin: 0, marginPercentage: 0 }
    const [first, second] = results
    const margin = first.votes - second.votes
    const marginPercentage = ((first.votesRatio ?? 0) - (second.votesRatio ?? 0)) * 100
    return { margin, marginPercentage }
  }

  const formatPercentage = (value: number): string => {
    return `${(value * 100).toFixed(2)}%`
  }

  const OTHER_PARTY_COLOR = '#DAA520'

  function summarizeElectionResults(electionType: ElectionType, results: ElectionResult[]): ElectionSummary | null {
    const sortedResults = accumulateAndSortVotes(results)
    if (sortedResults.length < 2) return null

    const [first, second, ...others] = sortedResults
    const otherParties: PartyVotes = {
      party: { name: 'Other' },
      votes: others.reduce((sum, party) => sum + party.votes, 0),
      votesRatio: others.reduce((sum, party) => sum + (party.votesRatio ?? 0), 0)
    }

    const firstLeadingParty = formatPartyData(electionType, first)
    const secondLeadingParty = formatPartyData(electionType, second)
    const otherLeadingParty = formatPartyData(electionType, otherParties, true)

    return {
      firstLeadingParty: { ...firstLeadingParty, constituencyWon: countConstituenciesWonByParty(results, first.party.name) },
      secondLeadingParty: { ...secondLeadingParty, constituencyWon: countConstituenciesWonByParty(results, second.party.name) },
      otherLeadingParty,
      ...calculateWinMargin(sortedResults)
    } as ElectionSummary
  }

  function formatPartyData(electionType: ElectionType, party: PartyVotes, isOther: boolean = false): LeadingParty {
    const info = partyInfo.find((p) => p.name === party.party.name)
    return {
      name: party.party.name,
      votes: party.votes,
      percentage: ((party.votesRatio ?? 0) * 100).toFixed(1),
      constituencyWon: 0,
      image: isOther ? OTHER_PARTY_COLOR : (electionType === ElectionType.PRESIDENTIAL || ElectionType.REGISTERED_VOTERS ? info?.candidate_image ?? unknown : info?.party_image ?? unknown),
      color: isOther ? OTHER_PARTY_COLOR : (info?.color ?? unknown)
    } as LeadingParty
  }

  function getRegions(electionType: ElectionType) {
    const store = electionType === ElectionType.PRESIDENTIAL ? presidentialMapStore : parliamentaryMapStore;
    const regionType = store.currentLevel === LayerLevel.REGION_SIXTEEN_CONSTITUENCY || LayerLevel.REGION_SIXTEEN ? RegionType.SIXTEEN : RegionType.TEN;
    return regionType === RegionType.SIXTEEN ? store.selectedSixteenRegions : store.selectedTenRegions;
  }

  function getSelectedConstituencies(electionType: ElectionType) {
    let store = null
    if (electionType === ElectionType.PRESIDENTIAL) {
      store = presidentialMapStore
    } else if (electionType === ElectionType.PARLIAMENTARY) {
      store = parliamentaryMapStore
    } else {
      store = registeredVotersMapStore
    }
    return store.selectedConstituencies.length < 1 ? store.filteredConstituencies : store.selectedConstituencies;
  }


  function createElectionResultComputed(electionType: ElectionType, getSelectedElection: () => { results: ElectionResult[] } | undefined) {
    return computed(() => {
      const constituencies = getSelectedConstituencies(electionType)
      const election = getSelectedElection()
      if (!election) return null

      const filteredResults = constituencies.length < 1
        ? election.results
        : election.results.filter(result =>
          constituencies.some(constituency => trimAndLowercase(constituency.name) === trimAndLowercase(result.constituency))
        )

      const summary = summarizeElectionResults(electionType, filteredResults)
      // const aggregatedResult = getConstituencyResults(electionType, constituencies.map(c => c.name))
      return summary
    })
  }



  const mPresidentialElectionResult = createElectionResultComputed(
    ElectionType.PRESIDENTIAL,
    () => mSelectedPresidentialElection.value
  );

  const mPresidentialElectionResultS = createElectionResultComputed(
    ElectionType.REGISTERED_VOTERS,
    () => mSelectedPresidentialElection.value
  );

  const mParliamentaryElectionResult = createElectionResultComputed(
    ElectionType.PARLIAMENTARY,
    () => mSelectedParliamentaryElection.value
  );

  function getElectionResults(electionType: ElectionType): ElectionResult[] {
    switch (electionType) {
      case ElectionType.PRESIDENTIAL:
        return mSelectedPresidentialElection.value?.results ?? []
      case ElectionType.PARLIAMENTARY:
        return mSelectedParliamentaryElection.value?.results ?? []
      default:
        return []
    }
  }

  function filterElectionResults(results: ElectionResult[], filterKey: 'constituency' | 'region', filterValues: string[]): ElectionResult[] {
    return results.filter(
      (item) =>
        item[filterKey] && filterValues.some(
          (filterValue) => trimAndLowercase(item[filterKey]) === trimAndLowercase(filterValue)
        )
    )
  }

  function aggregateElectionResults(results: ElectionResult[]): AggregatedElectionResult {
    const aggregatedResult: AggregatedElectionResult = {
      id: 0,
      registeredVoters: 0,
      totalCastVotes: 0,
      totalValidVotes: 0,
      totalRejectedVotes: 0,
      turnout: 0,
      region: '',
      constituency: '',
      pollingStationCode: '',
      pollingStation: '',
      partyVotes: []
    }

    results.forEach((result) => {
      aggregatedResult.registeredVoters += result.registeredVoters
      aggregatedResult.totalCastVotes += result.totalCastVotes
      aggregatedResult.totalValidVotes += result.totalValidVotes
      aggregatedResult.totalRejectedVotes += result.totalRejectedVotes
      aggregatedResult.turnout += result.turnout
    })

    aggregatedResult.partyVotes = accumulateAndSortVotes(results)

    return aggregatedResult
  }

  function getConstituencyResults(electionType: ElectionType, constituency: string[]): AggregatedElectionResult {
    const allResults = getElectionResults(electionType)
    const filteredResults = filterElectionResults(allResults, 'constituency', constituency)
    const aggregatedResult = aggregateElectionResults(filteredResults)
    aggregatedResult.constituency = ''
    return aggregatedResult
  }

  function getRegionResults(electionType: ElectionType, region: string, isTenRegions: boolean = false): AggregatedElectionResult {
    const allResults = getElectionResults(electionType)
    const regionNames = isTenRegions ? getRegionAliases(region) : [region];
    const filteredResults = filterElectionResults(allResults, 'region', regionNames)
    const aggregatedResult = aggregateElectionResults(filteredResults)
    aggregatedResult.region = region
    aggregatedResult.partyVotes = aggregatedResult.partyVotes.map(partyVote => ({
      ...partyVote,
      constCount: countConstituenciesWonByParty(filteredResults, partyVote.party.name)
    }))
    return aggregatedResult
  }

  function getResultRegion(electionType: ElectionType, region: string, isTenRegions: boolean = false) {
    const regionNames = isTenRegions ? getRegionAliases(region) : [region];
    let electionResults: ElectionResult[] | ElectionResult[] = []

    const selectedElection = electionType === ElectionType.PRESIDENTIAL
      ? mSelectedPresidentialElection.value
      : mSelectedParliamentaryElection.value;

    electionResults = selectedElection?.results.filter(
      (item) => item.region && regionNames.some(
        (reg) => trimAndLowercase(item.region) === trimAndLowercase(reg)
      )
    ) || [];

    if (!electionResults || electionResults.length < 1) return []

    return accumulateAndSortVotes(electionResults)
  }

  function getRegionAliases(region: string): string[] {
    switch (trimAndLowercase(region)) {
      case 'northern':
        return ['northern', 'north east', 'savannah'];
      case 'brong ahafo':
        return ['bono', 'bono east', 'ahafo'];
      case 'volta':
        return ['volta', 'oti'];
      case 'western':
        return ['western', 'western north'];
      default:
        return [region];
    }
  }

  function getResultConstituency(electionType: ElectionType, constituency: string) {
    let electionResults: ElectionResult[] | ElectionResult[] = []

    const selectedElection = electionType === ElectionType.PRESIDENTIAL
      ? mSelectedPresidentialElection.value
      : mSelectedParliamentaryElection.value;

    electionResults = selectedElection?.results.filter(
      (item) => item.constituency &&
        trimAndLowercase(item.constituency) === trimAndLowercase(constituency)
    ) || [];

    if (!electionResults || electionResults.length < 1) return []

    return accumulateAndSortVotes(electionResults)
  }

  function accumulateAndSortVotes(
    electionResults: ElectionResult[]
  ): PartyVotes[] {
    const accumulatedPartyVotes: PartyVotes[] = electionResults.reduce(
      (acc: PartyVotes[], result) => {
        result.partyVotes.forEach((partyVote) => {
          if (partyVote.party.name) {
            // Check if the party already exists in the accumulator
            const existingPartyVote = acc.find((pv) => pv.party.name === partyVote.party.name)
            if (existingPartyVote) {
              existingPartyVote.votes += partyVote.votes // Accumulate votes
            } else {
              // If not, add a new entry for the party
              acc.push({
                party: partyVote.party,
                candidate: partyVote.candidate,
                votes: partyVote.votes
              })
            }
          }
        })
        return acc
      },
      []
    )

    // Step 2: Sort the accumulated votes by number of votes in descending order
    const sortedVotes = accumulatedPartyVotes.sort((a, b) => b.votes - a.votes)

    // Step 3: Calculate total votes
    const totalVotes = sortedVotes.reduce((acc, partyVote) => acc + partyVote.votes, 0)

    // Step 4: Calculate the vote ratio for each party
    return accumulatedPartyVotes.map((partyVote) => ({
      ...partyVote,
      votesRatio: totalVotes > 0 ? partyVote.votes / totalVotes : 0
    }))
  }

  function countConstituenciesWonByParty(electionResults: ElectionResult[], partyName: string): number {

    // Step 1: Group results by constituency
    const constituencies = electionResults.reduce(
      (acc: { [key: string]: ElectionResult[] }, result) => {
        if (result.constituency) {
          if (!acc[result.constituency]) {
            acc[result.constituency] = []
          }
          acc[result.constituency].push(result)
        }
        return acc
      },
      {}
    )

    let constituenciesWon = 0

    // Step 2: For each constituency, accumulate votes and find the winner without sorting
    Object.values(constituencies).forEach((constituencyResults) => {
      const voteMap: { [partyName: string]: number } = {}

      // Accumulate votes for each party in this constituency
      constituencyResults.forEach((result) => {
        result.partyVotes.forEach((partyVote) => {
          if (partyVote.party.name) {
            voteMap[partyVote.party.name] = (voteMap[partyVote.party.name] || 0) + partyVote.votes
          }
        })
      })

      // Step 3: Find the party with the maximum votes
      let maxVotes = 0
      let winningParty = ''

      for (const [currentPartyName, votes] of Object.entries(voteMap)) {
        if (votes > maxVotes) {
          maxVotes = votes
          winningParty = currentPartyName
        }
      }

      // Step 4: Check if the winning party is the party we are interested in
      if (winningParty === partyName) {
        constituenciesWon++
      }
    })

    return constituenciesWon
  }


  const setPresidentialElection = async (year: string) => {
    const result = mPresidentialElections.value.find(e => e.year === year)
    if (result) {
      mSelectedPresidentialElection.value = result
    }
  }

  // Add more election-related actions and getters as needed
  const getPresidentialElections = async () => {
    const result = await presidentialElectionService.getElections()

    result.fold(
      (value) => {
        mPresidentialElections.value = value as Election[]
        mSelectedPresidentialElection.value = mPresidentialElections.value[0]

      },
      (error) => console.log(error)
    )
  }

  const getParliamentaryElections = async () => {

    const result = await parliamentaryElectionService.getParliamentaryElections()

    result.fold(
      (value) => {
        mParliamentaryElections.value = value as Election[]
        mSelectedParliamentaryElection.value = mParliamentaryElections.value[0]
      },
      (error) => console.log(error)
    )
  }

  const getPresidentialElectionResults = async () => {
    const result = await presidentialElectionService.getElections()

    result.fold(
      (value) => {
        mPresidentialElections.value = value as Election[]
        mPresidentialElectionResults.value = mPresidentialElections.value[0].results

      },
      (error) => console.log(error)
    )
  }


  return {
    presidentialElections: mPresidentialElections,
    parliamentaryElections: mParliamentaryElections,
    selectedPresidentialElection: mSelectedPresidentialElection,
    selectedParliamentaryElection: mSelectedParliamentaryElection,
    presidentialElectionResult: mPresidentialElectionResult,
    presidentialElectionResultS: mPresidentialElectionResultS,
    parliamentaryElectionResult: mParliamentaryElectionResult,
    getResultRegion,
    getResultConstituency,
    getRegionResults,
    getConstituencyResults,
    calculateVotePercentage,
    calculateWinMargin,
    getPresidentialElections,
    getPresidentialElectionResults,
    getParliamentaryElections,
    accumulateAndSortVotes,
    resetStore,
    setPresidentialElection
  }
})
