<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { FilterMatchMode } from 'primevue/api';

import PollingCenterCreateOrEditForm from './PollingCenterCreateOrEditForm.vue';

import { usePollingCenterIndexStore } from '../stores/polling-center-index.store';
import { usePollingCenterCreateOrEditFormStore } from '../stores/polling-center-create-or-edit-form.store';

import { formatCoordinates } from '../../core/utils/maps.util'

const indexStore = usePollingCenterIndexStore()
const createOrEditFormStore = usePollingCenterCreateOrEditFormStore()

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    region: { value: null, matchMode: FilterMatchMode.IN },
    constituency: { value: null, matchMode: FilterMatchMode.IN },
    electoralArea: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    agentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    agentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const tableContainer = ref<HTMLElement | null>(null)

// Define observer outside the lifecycle hooks
let observer: IntersectionObserver | null = null;

const observerCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            indexStore.startInterval(); // Start API polling
        } else {
            indexStore.stopInterval(); // Stop API polling
        }
    });
};


onMounted(async () => {
    indexStore.getPollingCenters();
    observer = new IntersectionObserver(observerCallback);
    if (tableContainer.value) {
        observer.observe(tableContainer.value);
    }
})

onUnmounted(() => {
    if (observer && tableContainer.value) {
        observer.unobserve(tableContainer.value);
    }
    indexStore.stopInterval(); // Ensure interval stops on unmount
    observer = null;
});

</script>

<template>
    <div ref="tableContainer" class="table-container">
        <div class="card">
            <DataTable v-model:filters="filters" v-model:selection="indexStore.selectedPollingCenters"
                :value="indexStore.pollingCenters" :loading="indexStore.isLoading" stripedRows scrollable removableSort
                paginator
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rows="10" :rowsPerPageOptions="[10, 20, 30, 40, 50]" dataKey="id" filterDisplay="row"
                :globalFilterFields="['region', 'constituency', 'electoralArea', 'code', 'name', 'agentId', 'agentName']"
                tableStyle="min-width: 30rem">
                <template #header>
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Manage Polling Centers</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Search..." />
                            <Button icon="pi pi-file-export" severity="success" label="Export as CSV"
                                @click="indexStore.exportDataAsCsv()" />
                        </span>
                    </div>
                </template>
                <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>

                <Column field="region" header="Region" sortable :showFilterMenu="false" style="min-width: 10rem">
                    <template #filter="{ filterModel, filterCallback }">
                        <MultiSelect v-model="filterModel.value" @change="filterCallback()"
                            :options="indexStore.regions" filter placeholder="Any" class="p-column-filter"
                            style="min-width: 10rem" :maxSelectedLabels="1">

                        </MultiSelect>
                    </template>
                </Column>
                <Column field="constituency" header="Constituency" sortable :showFilterMenu="false"
                    style="min-width: 14rem">
                    <template #filter="{ filterModel, filterCallback }">
                        <MultiSelect v-model="filterModel.value" @change="filterCallback()"
                            :options="indexStore.constituencies" filter placeholder="Any" class="p-column-filter"
                            style="min-width: 14rem" :maxSelectedLabels="1">
                        </MultiSelect>
                    </template>
                </Column>
                <Column field="electoralArea" header="Electoral Area" sortable :showFilterMenu="false"
                    style="min-width: 14rem">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search by Electoral Area" style="min-width: 14rem" />
                    </template>
                </Column>
                <Column field="code" header="Polling Center Code" sortable style="min-width: 8rem">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search by Code" style="min-width: 8rem" />
                    </template>
                </Column>
                <Column field="name" header="Polling Center" sortable style="min-width: 14rem">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search by Polling Center" style="min-width: 14rem" />
                    </template>
                </Column>
                <Column header="Image" :sortable="false">
                    <template #body="slotProps">
                        <Image :src="slotProps.data.imageUrl" alt="Image" preview imageClass="w-3rem border-round" />
                    </template>
                </Column>
                <Column header="Coordinates" :sortable="false" style="min-width: 10rem">
                    <template #body="{ data }">
                        <a :href="data.mapsLink" target="_blank" rel="noopener noreferrer">
                            {{ formatCoordinates(data.latitude, data.longitude) }}
                        </a>
                    </template>
                </Column>
                <Column field="agentId" header="Agent ID" sortable style="min-width: 10rem">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search by Agent ID" style="min-width: 10rem" />
                    </template>
                </Column>
                <Column field="agentName" header="Agent Name" sortable style="min-width: 14rem">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search by Agent Name" style="min-width: 14rem" />
                    </template>
                </Column>
                <Column field="actions" header="Actions" frozen>
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2"
                            @click="createOrEditFormStore.editPollingCenter(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>


            <PollingCenterCreateOrEditForm />
        </div>
    </div>
</template>