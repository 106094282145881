import type ElectionDto from '../dtos/election.dto'
import type { ElectionResultDto, ParliamentaryElectionResultDto, PresidentialElectionResultDto } from '../dtos/election-result.dto'
import type Election from '../models/election.model'
import type ElectionResult from '../models/election-result.model'
import type { PresidentialSlipDto } from '../dtos/slip.dto'
import type PartyVotes from '../models/party_votes.model'
import type Party from '../models/party.model'
import { PartyName } from '../enums/party-name.enum'

import { mapPartyVotesFromPartyVotesDTO } from './party.mapper'
import { trimAndUppercase } from '@/modules/core/utils/string.util'

export function mapElectionFromElectionDTO(
  dto?: ElectionDto | null
): Election | null {
  return !dto
    ? null
    : ({
      year: dto.year,
      results: dto.results.map(mapElectionResultFromElectionResultDTO)
    } as Election)
}


export function mapElectionResultFromElectionResultDTO(
  dto?: ElectionResultDto | null
): ElectionResult | null {
  return !dto
    ? null
    : ({
      id: 0,
      region: trimAndUppercase(dto.region),
      constituency: trimAndUppercase(dto.constituency),
      registeredVoters: dto.total_registered_voters,
      totalCastVotes: dto.total_cast_votes,
      totalValidVotes: dto.total_valid_votes,
      totalRejectedVotes: dto.total_rejected_votes,
      turnout: dto.turnout,
      abstained: dto.abstained,
      partyVotes: dto.parties.map(mapPartyVotesFromPartyVotesDTO),
    } as ElectionResult)
}
export function mapElectionResultFromSlipDTO(
  dto?: PresidentialSlipDto | null
): ElectionResult | null {
  if (!dto) {
    return null
  }
  const partyVotes: PartyVotes[] = [
    { party: { name: PartyName.NPP } as Party, votes: parseInt(dto.npp, 10) || 0 },
    { party: { name: PartyName.NDC } as Party, votes: parseInt(dto.ndc, 10) || 0 },
    { party: { name: PartyName.GUM } as Party, votes: parseInt(dto.gum, 10) || 0 },
    { party: { name: PartyName.CPP } as Party, votes: parseInt(dto.cpp, 10) || 0 },
    { party: { name: PartyName.GFP } as Party, votes: parseInt(dto.gfp, 10) || 0 },
    { party: { name: PartyName.GCPP } as Party, votes: parseInt(dto.gcpp, 10) || 0 },
    { party: { name: PartyName.APC } as Party, votes: parseInt(dto.apc, 10) || 0 },
    { party: { name: PartyName.LPG } as Party, votes: parseInt(dto.lpg, 10) || 0 },
    { party: { name: PartyName.NDP } as Party, votes: parseInt(dto.ndp, 10) || 0 },
    { party: { name: PartyName.KOFI_KORANTENG } as Party, votes: parseInt(dto.kofi_koranteng, 10) || 0 },
    { party: { name: PartyName.GEORGE_ADU } as Party, votes: parseInt(dto.george_adu, 10) || 0 },
    { party: { name: PartyName.NANA_BEDIAKO } as Party, votes: parseInt(dto.nana_bediako, 10) || 0 },
    { party: { name: PartyName.ALAN_KYEREMANTEN } as Party, votes: parseInt(dto.alan_kyeremanten, 10) || 0 },
    { party: { name: PartyName.INDEPENDENT } as Party, votes: parseInt(dto.independent, 10) || 0 }
  ];
  return {
    id: 0,
    region: trimAndUppercase(dto.region_name),
    constituency: trimAndUppercase(dto.constituency_name),
    pollingStationCode: trimAndUppercase(dto.polling_station_code),
    pollingStation: trimAndUppercase(dto.polling_station_name),
    registeredVoters: 0,
    totalCastVotes: 0,
    totalValidVotes: 0,
    totalRejectedVotes: 0,
    turnout: 0,
    partyVotes: partyVotes,
  } as ElectionResult
}


export function mapElectionResultFromPresidentialElectionResultDto(
  dto?: PresidentialElectionResultDto | null
): ElectionResult | null {
  if (!dto) {
    return null
  }
  const partyVotes: PartyVotes[] = [
    { party: { name: PartyName.NPP } as Party, votes: parseInt(dto.npp, 10) || 0 },
    { party: { name: PartyName.NDC } as Party, votes: parseInt(dto.ndc, 10) || 0 },
    { party: { name: PartyName.GUM } as Party, votes: parseInt(dto.gum, 10) || 0 },
    { party: { name: PartyName.CPP } as Party, votes: parseInt(dto.cpp, 10) || 0 },
    { party: { name: PartyName.GFP } as Party, votes: parseInt(dto.gfp, 10) || 0 },
    { party: { name: PartyName.GCPP } as Party, votes: parseInt(dto.gcpp, 10) || 0 },
    { party: { name: PartyName.APC } as Party, votes: parseInt(dto.apc, 10) || 0 },
    { party: { name: PartyName.LPG } as Party, votes: parseInt(dto.lpg, 10) || 0 },
    { party: { name: PartyName.NDP } as Party, votes: parseInt(dto.ndp, 10) || 0 },
    { party: { name: PartyName.KOFI_KORANTENG } as Party, votes: parseInt(dto.kofi_koranteng, 10) || 0 },
    { party: { name: PartyName.GEORGE_ADU } as Party, votes: parseInt(dto.george_adu, 10) || 0 },
    { party: { name: PartyName.NANA_BEDIAKO } as Party, votes: parseInt(dto.nana_bediako, 10) || 0 },
    { party: { name: PartyName.ALAN_KYEREMANTEN } as Party, votes: parseInt(dto.alan_kyeremanten, 10) || 0 },
  ];
  return {
    id: 0,
    region: trimAndUppercase(dto.region),
    constituency: trimAndUppercase(dto.constituency),
    pollingStationCode: "",
    pollingStation: "",
    registeredVoters: 0,
    totalCastVotes: dto.total_cast_votes,
    totalValidVotes: dto.total_valid_votes,
    totalRejectedVotes: dto.total_rejected_votes,
    turnout: 0,
    partyVotes: partyVotes,
  } as ElectionResult
}

export function mapElectionResultFromParliamentaryElectionResultDto(
  dto?: ParliamentaryElectionResultDto | null
): ElectionResult | null {
  if (!dto) {
    return null
  }
  const partyNames = Object.values(PartyName)

  // console.log('mapElectionResultFromParliamentaryElectionResultDto::dto:', dto)
  // console.log('mapElectionResultFromParliamentaryElectionResultDto::partyNames:', partyNames)
  const partyVotes: PartyVotes[] = [];
  const candidates = [
    { party: dto.candidate1_party, name: dto.candidate1, votes: dto.candidate1_votes },
    { party: dto.candidate2_party, name: dto.candidate2, votes: dto.candidate2_votes },
    { party: dto.candidate3_party, name: dto.candidate3, votes: dto.candidate3_votes },
    { party: dto.candidate4_party, name: dto.candidate4, votes: dto.candidate4_votes },
    { party: dto.candidate5_party, name: dto.candidate5, votes: dto.candidate5_votes },
    { party: dto.candidate6_party, name: dto.candidate6, votes: dto.candidate6_votes },
    { party: dto.candidate7_party, name: dto.candidate7, votes: dto.candidate7_votes },
    { party: dto.candidate8_party, name: dto.candidate8, votes: dto.candidate8_votes },
    { party: dto.candidate9_party, name: dto.candidate9, votes: dto.candidate9_votes },
    { party: dto.candidate10_party, name: dto.candidate10, votes: dto.candidate10_votes },
    { party: dto.candidate11_party, name: dto.candidate11, votes: dto.candidate11_votes },
    { party: dto.candidate12_party, name: dto.candidate12, votes: dto.candidate12_votes },
    { party: dto.candidate13_party, name: dto.candidate13, votes: dto.candidate13_votes },
  ];

  // console.log('mapElectionResultFromParliamentaryElectionResultDto::candidates:', candidates)
  partyNames.forEach((partyName) => {
    candidates.forEach((candidate) => {
      if (candidate.party === partyName) {
        partyVotes.push({
          party: { name: partyName } as Party,
          candidate: candidate.name,
          votes: candidate.votes ? parseInt(candidate.votes, 10) : 0,
        });
      }
    });
  });

  // Define a placeholder object for PartyVotes
  const placeholder = {
    party: { name: "N/A" } as Party,
    candidate: "N/A",
    votes: 0,
  };

  // Ensure at least 5 elements in partyVotes
  while (partyVotes.length < 5) {
    partyVotes.push(placeholder);
  }

  return {
    id: 0,
    region: trimAndUppercase(dto.region),
    constituency: trimAndUppercase(dto.constituency),
    pollingStationCode: "",
    pollingStation: "",
    registeredVoters: 0,
    totalCastVotes: dto.total_cast_votes,
    totalValidVotes: dto.total_valid_votes,
    totalRejectedVotes: dto.total_rejected_votes,
    turnout: 0,
    partyVotes: partyVotes,
  } as ElectionResult
}
