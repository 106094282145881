export const formatDate = (value?: Date | null) => {
  return !value
    ? ''
    : value.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
}

export const formatTime = (value?: Date | null) => {
  if (!value) {
    return ''
  }

  // Extract hours, minutes, and seconds from the Date object
  const hours = value.getHours().toString().padStart(2, '0')
  const minutes = value.getMinutes().toString().padStart(2, '0')
  const seconds = value.getSeconds().toString().padStart(2, '0')

  // Return the formatted time string
  return `${hours}:${minutes}:${seconds}`
}

export const formatCompanySubscriptionDate = (value?: Date | null): string => {
  if (!value) {
    return ''
  }

  // Get day, month, and year from the date object
  const day = value.getDate().toString().padStart(2, '0')
  const month = value.toLocaleString('en-US', { month: 'short' })
  const year = value.getFullYear()

  // Construct the formatted date string
  return `${day}-${month}-${year}`
}

export const formatTripDate = (value?: Date | null): string => {
  if (!value) {
    return ''
  }

  const year = value.getFullYear()
  const month = String(value.getMonth() + 1).padStart(2, '0')
  const day = String(value.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const formatChatDate = (value: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: '2-digit'
  }).format(value)
}
export const formatChatTime = (value: Date): string => {
  return value.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
}

export const checkDateStatus = (targetDate: Date): string => {
  const currentDate = new Date()
  const expirationDate = new Date(targetDate)

  // Calculate the date 3 months before the target date
  const threeMonthsBefore = new Date(expirationDate)
  threeMonthsBefore.setMonth(expirationDate.getMonth() - 3)

  if (currentDate > expirationDate) {
    return 'expired'
  } else if (currentDate >= threeMonthsBefore && currentDate <= expirationDate) {
    return 'expiring'
  } else {
    return 'active'
  }
}
