export interface PollingCenter {
    id: number;
    region: string;
    constituency: string;
    electoralArea?: string | null;
    code: string;
    name: string;
    latitude: number;
    longitude: number;
    imageUrl: string;
    mapsLink: string;
    agentId: string;
    agentName: string;
}

export const emptyPollingCenter: PollingCenter = {
    id: 0,
    region: '',
    constituency: '',
    code: '',
    name: '',
    latitude: 0,
    longitude: 0,
    imageUrl: '',
    mapsLink: '',
    agentId: '',
    agentName: ''
}