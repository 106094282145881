<script setup lang="ts">
import { computed } from 'vue'
interface Props {
  name?: string
  party: string
  electoralVotes?: number
  popularVotes?: number
  imageUrl?: string
  color?: string
}

const props = defineProps<Props>()

const cardBackgroundStyle = computed(() => ({
  backgroundColor: `${props.color}4D`
}))
const nameStyle = computed(() => ({
  color: `${props.color}`
}))
</script>

<template>
  <div class="electoral-card-lite" :style="cardBackgroundStyle">
    <div class="image-container">
      <img :src="imageUrl" :alt="`Photo of ${name}`" class="candidate-image" />
    </div>
    <div class="content">
      <h2 class="name" :style="nameStyle">{{ name }}</h2>
      <!-- <div class="party-tag">{{ party }}</div> -->

      <div class="votes-container">
        <div class="vote-section">
          <div class="vote-number">{{ electoralVotes }}</div>
          <div class="vote-label">Const.</div>
        </div>

        <div class="vote-section">
          <div class="vote-number">{{ popularVotes ? popularVotes.toLocaleString() : '' }}</div>
          <div class="vote-label">Votes</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.electoral-card-lite {
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  padding: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  min-width: 150px;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.electoral-card-lite .content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

.electoral-card-lite .name {
  font-size: clamp(1.25rem, 2vw, 1.5rem);
  font-weight: 600;
  margin-bottom: 0.25rem;
  line-height: 1.2;
  text-align: center;
}

.electoral-card-lite .party-tag {
  display: inline-block;
  background-color: #000;
  color: white;
  text-transform: uppercase;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;
  font-size: clamp(0.7rem, 1.5vw, 0.75rem);
  letter-spacing: 0.05em;
  margin-bottom: 0.75rem;
}

.electoral-card-lite .votes-container {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  width: 100%;
}

.electoral-card-lite .vote-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.electoral-card-lite .vote-number {
  font-size: clamp(1.5rem, 2vw, 1.75rem);
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.electoral-card-lite .vote-label {
  color: #4b5563;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
}

.electoral-card-lite .image-container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.electoral-card-lite .candidate-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Hover effects */
.electoral-card-lite {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.electoral-card-lite:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Animation for numbers */
@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.electoral-card-lite .vote-number {
  animation: countUp 0.5s ease-out forwards;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: 1fr;
    max-width: 400px;
  }

  .electoral-card-lite {
    min-width: unset;
  }
}
</style>