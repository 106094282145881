import type { PollingStationDto } from "../dtos/polling-station.dto"
import type { PollingStation } from "../models/polling-station.model"


export function mapPollingStationsFromPollingStationsDto(dto?: PollingStationDto | null): PollingStation | null {
  return !dto
    ? null
    : ({
      id: dto.id,
      region: dto.region,
      constituency: dto.constituency,
      code: dto.code,
      name: dto.name,
      totalVoters: Number(dto.total_voters),
    } as PollingStation)
}
