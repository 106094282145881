import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'
import L from 'leaflet'
import type { FeatureCollection } from 'geojson'
import {
  defaultRegionLayerStyle,
  defaultConstituencyLayerStyle,
  highlightLayerStyle,
  selectedLayerStyle,
  unknown
} from '@/modules/map/utils/map-layer-styles'
import { LayerLevel } from '@/modules/map/enums/layer-level.enum'
import { ElectionType } from '@/modules/election/enums/election-type.enum'

import type Region from '@/modules/data/models/region.model'
import type Constituency from '@/modules/data/models/constituency.model'
import type GroupedData from '@/modules/data/models/grouped_data.model'

import { useVoterStore } from '@/modules/voter/stores/voter.store'
import { useDataStore } from '@/modules/data/stores/data.store'
import { useElectionStore } from '@/modules/election/stores/election-map.store'

import { trimAndLowercase } from '../../core/utils/string.util'

import partyInfo from '@/assets/election/party_info.json'
import type AggregatedElectionResult from '@/modules/election/models/aggregated_election_result.model'
import type { LegendItem } from '../models/legend-item.model'


export const useParliamentaryMapStore = defineStore('parliamentaryMap', () => {
  const mDataStore = useDataStore()
  const mElectionStore = useElectionStore()
  const mVoterStore = useVoterStore()

  const mSelectedSixteenRegions = ref<Region[]>([])
  const mSelectedTenRegions = ref<Region[]>([])

  const mFilteredConstituencies = ref<Constituency[]>([])
  const mSelectedConstituencies = ref<Constituency[]>([])

  const mSelectedGroupedData = ref<GroupedData | null | undefined>(null)

  const mMap = ref<L.Map | null>(null)
  const mCurrentLevel = ref<LayerLevel | null>(LayerLevel.REGION_SIXTEEN)
  const mNationalBounds = ref<L.LatLngBounds | null | undefined>(null)
  const mBoundsRectangle = ref<L.Rectangle[]>([])

  const mDetailedColorMode = ref<boolean>(true)
  const mDoubleClicked = ref<boolean>(false)

  const isLegendVisible = ref<boolean>(true)

  function resetStore() {
    mSelectedSixteenRegions.value = []
    mSelectedTenRegions.value = []

    mSelectedConstituencies.value = []

    mCurrentLevel.value = LayerLevel.REGION_SIXTEEN
    setActiveLayer(mCurrentLevel.value)
    mBoundsRectangle.value = []
    mDetailedColorMode.value = false
  }

  const layers = {
    sixteenRegions: null as L.GeoJSON | null,
    tenRegions: null as L.GeoJSON | null,
    constituency: null as L.GeoJSON | null
  }

  const defaultMapView = {
    center: [7.9465, -1.0232],
    zoom: 8
  }

  watch(mSelectedSixteenRegions, (selectedRegions) => {
    if (selectedRegions.length < 1 && (mCurrentLevel.value === LayerLevel.REGION_SIXTEEN_CONSTITUENCY)) {

      const activeLayer = LayerLevel.REGION_SIXTEEN
      setActiveLayer(activeLayer)
      mSelectedSixteenRegions.value = []
      mSelectedConstituencies.value = []
    }
    if (selectedRegions.length >= 1) {
      mCurrentLevel.value = LayerLevel.REGION_SIXTEEN_CONSTITUENCY;
      filterConstituencies()
      if (mMap.value && layers.constituency) {
        const bounds = layers.constituency.getBounds()
        mMap.value.fitBounds(bounds)
      }
      setActiveLayer(LayerLevel.REGION_SIXTEEN_CONSTITUENCY)
    }

  })


  watch(mSelectedTenRegions, (selectedRegions) => {
    if (selectedRegions.length < 1 && mCurrentLevel.value === LayerLevel.REGION_TEN_CONSTITUENCY) {

      setActiveLayer(LayerLevel.REGION_TEN)
      mSelectedSixteenRegions.value = []
      mSelectedConstituencies.value = []
    }
    if (selectedRegions.length >= 1) {
      mCurrentLevel.value = LayerLevel.REGION_TEN_CONSTITUENCY;
      filterConstituencies()
      if (mMap.value && layers.constituency) {
        const bounds = layers.constituency.getBounds()
        mMap.value.fitBounds(bounds)
      }
      setActiveLayer(LayerLevel.REGION_TEN_CONSTITUENCY)
    }
  })

  watch(mSelectedConstituencies, () => {
    updateLayers()
  })

  watch(mSelectedGroupedData, (selectedGroupedData) => {
    if (selectedGroupedData) {
      if (mCurrentLevel.value === LayerLevel.CONSTITUENCY) {
        const constituencies = selectedGroupedData.constituencies.map((c) => c.trim().toLowerCase())

        mSelectedConstituencies.value = mDataStore.constituencies.filter((c) => constituencies.includes(c.name.trim().toLowerCase()))
      }
    }
  })

  watch(mDetailedColorMode, () => {
    updateLayers()
  })

  const mUpdatedConstituencies = computed(() => {
    const filteredConstituencies = mFilteredConstituencies.value

    if (filteredConstituencies.length > 0) {
      const constituenciesResult = mElectionStore.getConstituencyResults(ElectionType.PARLIAMENTARY, filteredConstituencies.map(c => c.name))
      return constituenciesResult
    }

    return {
      id: 0,
      region: '',
      constituency: '',
      registeredVoters: 0,
      totalCastVotes: 0,
      totalValidVotes: 0,
      totalRejectedVotes: 0,
      turnout: 0,
      abstained: null,
      pollingStationCode: '',
      pollingStation: '',
      partyVotes: [],
    } as AggregatedElectionResult
  })

  const mUpdatedSelectedConstituencies = computed(() => {
    const selectedConstituencies = mSelectedConstituencies.value

    if (selectedConstituencies.length > 0) {
      // const voters = mVoters.value.filter((voter) => voter.constituency !== undefined)

      return selectedConstituencies.map((c) => {
        const constituencyName = c.name

        const constResult = mElectionStore.getConstituencyResults(ElectionType.PARLIAMENTARY, [constituencyName])
        const constVoters = mVoterStore.getVotersConstituency(constituencyName)

        return {
          ...c,
          result: constResult,
          voters: constVoters
        }
      })
    }

    return []
  })

  const mUpdatedSixteenRegions = computed(() => {
    const selectedRegions = mSelectedSixteenRegions.value

    if (selectedRegions.length > 0) {
      return selectedRegions.map((r) => {
        const regionName = r.name

        const constResult = mElectionStore.getRegionResults(ElectionType.PARLIAMENTARY, regionName, mCurrentLevel.value === LayerLevel.REGION_TEN)
        const constVoters = mVoterStore.getVotersRegion(regionName)

        return {
          ...r,
          result: constResult,
          voters: constVoters
        }
      })
    }
    return []
  })

  const mUpdatedTenRegions = computed(() => {
    const selectedRegions = mSelectedTenRegions.value

    if (selectedRegions.length > 0) {
      return selectedRegions.map((r) => {
        const regionName = r.name

        const constResult = mElectionStore.getRegionResults(ElectionType.PARLIAMENTARY, regionName, mCurrentLevel.value === LayerLevel.REGION_TEN)
        const constVoters = mVoterStore.getVotersRegion(regionName)

        return {
          ...r,
          result: constResult,
          voters: constVoters
        }
      })
    }
    return []
  })

  const mLegendItems = computed(() => {
    const legendItems: LegendItem[] =
      mDetailedColorMode.value ?
        [
          { id: 1, label: '', colorOne: '#37B2F7', colorTwo: '#85AC91', value: '2% and below' },
          { id: 2, label: '', colorOne: '#176FFC', colorTwo: '#4D8464', value: '3% - 59%' },
          { id: 3, label: '', colorOne: '#01009C', colorTwo: '#175D39', value: '60% and above' },
        ] :
        [
          { id: 2, label: '', colorOne: '#176FFC', colorTwo: '#4D8464', value: '59% and below' },
          { id: 3, label: '', colorOne: '#01009C', colorTwo: '#175D39', value: '60% and above' },
        ]

    return legendItems
  })

  function filterConstituencies() {
    if (!mDataStore.constituencyGeoJson) return;

    layers.constituency?.clearLayers();
    const isTenRegions = mCurrentLevel.value === LayerLevel.REGION_TEN_CONSTITUENCY;
    const selectedRegions = isTenRegions ? mSelectedTenRegions.value : mSelectedSixteenRegions.value;
    const regionProperty = isTenRegions ? 'ten_regions' : 'region';

    const constituencyGeoJsonData: FeatureCollection = {
      type: 'FeatureCollection',
      features: selectedRegions.length >= 1
        ? mDataStore.constituencyGeoJson.features.filter((feature) =>
          selectedRegions.some(
            (region) => trimAndLowercase(region.name) === trimAndLowercase(feature.properties?.[regionProperty])
          )
        )
        : mDataStore.constituencyGeoJson.features
    };

    mFilteredConstituencies.value = constituencyGeoJsonData.features.map((feature, index) => ({
      id: index + 1,
      name: feature.properties?.constituen,
      region: feature.properties?.region,
      regionOld: feature.properties?.region_old,
    }));

    layers.constituency?.addData(constituencyGeoJsonData as GeoJSON.GeoJsonObject);
  }


  function initializeMap(mapContainer: HTMLElement) {
    if (mapContainer) {
      mMap.value = L.map(mapContainer, {
        center: defaultMapView.center as L.LatLngExpression,
        zoom: defaultMapView.zoom,
        zoomAnimation: true,
        zoomSnap: 0.25,
        zoomDelta: 0.25,
        wheelDebounceTime: 40
      })
    }
  }

  function initializeLayers() {
    if (!mMap.value) return

    layers.sixteenRegions = L.geoJSON(mDataStore.sixteenRegionsGeoJson, {
      style: defaultRegionLayerStyle,
      onEachFeature: onEachRegion
    })

    layers.tenRegions = L.geoJSON(mDataStore.tenRegionsGeoJson, {
      style: defaultRegionLayerStyle,
      onEachFeature: onEachRegion
    })

    layers.constituency = L.geoJSON(mDataStore.constituencyGeoJson, {
      style: defaultConstituencyLayerStyle,
      onEachFeature: onEachConstituency
    })

    setActiveLayer(LayerLevel.REGION_SIXTEEN)
  }

  function setActiveLayer(level: LayerLevel) {
    if (!mMap.value) return;

    mCurrentLevel.value = level;

    // Remove all layers that are not applicable to the selected level
    if (layers.sixteenRegions && level !== LayerLevel.REGION_SIXTEEN) {
      mMap.value.removeLayer(layers.tenRegions as L.GeoJSON);
      mMap.value.removeLayer(layers.constituency as L.GeoJSON);
    }
    if (layers.tenRegions && level !== LayerLevel.REGION_TEN) {
      mMap.value.removeLayer(layers.sixteenRegions as L.GeoJSON);
      mMap.value.removeLayer(layers.constituency as L.GeoJSON);
    }
    if (layers.constituency && level !== LayerLevel.CONSTITUENCY
      && level !== LayerLevel.REGION_SIXTEEN_CONSTITUENCY
      && level !== LayerLevel.REGION_TEN_CONSTITUENCY) {
      mMap.value.removeLayer(layers.sixteenRegions as L.GeoJSON);
      mMap.value.removeLayer(layers.tenRegions as L.GeoJSON);
    }

    // Add the appropriate layer based on the selected level
    switch (level) {
      case LayerLevel.REGION_SIXTEEN:
        layers.sixteenRegions?.addTo(mMap.value as L.Map);
        break;
      case LayerLevel.REGION_TEN:
        layers.tenRegions?.addTo(mMap.value as L.Map);
        break;
      case LayerLevel.CONSTITUENCY:
      case LayerLevel.REGION_SIXTEEN_CONSTITUENCY:
      case LayerLevel.REGION_TEN_CONSTITUENCY:
        layers.constituency?.addTo(mMap.value as L.Map);
        break;
    }


    // Reset selections and apply filters when switching between region levels
    if (level === LayerLevel.REGION_SIXTEEN || level === LayerLevel.REGION_TEN) {

      // Adjust map bounds and zoom to the national bounds if they are not set
      if (!mNationalBounds.value) {
        mNationalBounds.value = (layers.sixteenRegions?.getBounds() || layers.tenRegions?.getBounds()) ?? null;
      }
      if (mNationalBounds.value) {
        mMap.value.fitBounds(mNationalBounds.value);
        // zoomToFeature(mNationalBounds.value);
      }
      mSelectedSixteenRegions.value = [];
      mSelectedConstituencies.value = [];
      mSelectedGroupedData.value = null;
      filterConstituencies();
    } else {
      mSelectedGroupedData.value = null;
    }

    updateLayers();
  }

  function getPartyColors(partyName: string) {
    const party = partyInfo.find(
      (p) => trimAndLowercase(p.name) === trimAndLowercase(partyName)
    )

    return {
      color: party?.color ?? unknown,
      colorMedium: party?.colorMedium ?? unknown,
      colorLow: mDetailedColorMode.value
        ? (party?.colorLow ?? unknown)
        : (party?.colorMedium ?? unknown)
    }
  }

  function getFillColor(area: string): string {
    const isConstituencyLevel =
      mCurrentLevel.value === LayerLevel.CONSTITUENCY ||
      mCurrentLevel.value === LayerLevel.REGION_SIXTEEN_CONSTITUENCY ||
      mCurrentLevel.value === LayerLevel.REGION_TEN_CONSTITUENCY;

    // Get the appropriate election result based on the current level
    const result = isConstituencyLevel
      ? mElectionStore.getResultConstituency(ElectionType.PARLIAMENTARY, area)
      : mElectionStore.getResultRegion(ElectionType.PARLIAMENTARY, area, mCurrentLevel.value === LayerLevel.REGION_TEN);

    if (!result || result.length < 1) {
      console.log('fill_color::else: ', area);
      return unknown; // Return default color if no results are available
    }

    const [firstLeadingParty, secondLeadingParty] = result;
    const firstRatio = firstLeadingParty.votesRatio ?? 0;
    const secondRatio = secondLeadingParty.votesRatio ?? 0;
    const thresholdLow = 0.02;
    const thresholdMedium = 0.6;

    // Helper to retrieve party colors based on the leading party's name
    const { color, colorMedium, colorLow } = getPartyColors(
      firstRatio >= secondRatio
        ? firstLeadingParty.party.name
        : secondLeadingParty.party.name
    );

    // Determine fill color based on vote ratios
    if (Math.abs(firstRatio - secondRatio) <= thresholdLow) {
      return firstRatio < secondRatio ? colorLow : colorLow; // Return colorLow based on leading party
    }

    const leadingRatio = Math.max(firstRatio, secondRatio);
    return leadingRatio < thresholdMedium ? colorMedium : color;
  }


  function updateLayers() {
    const updateLayerStyle = (
      layer: L.Layer,
      regionOrConstituency: string,
      isSelected: boolean,
      defaultWeight: number
    ) => {
      const layerS = layer as L.Path;
      const regionNameNormalized = trimAndLowercase(regionOrConstituency);
      const fillColor = getFillColor(regionNameNormalized);
      const weight = isSelected ? selectedLayerStyle.weight : defaultWeight;

      const currentStyle = layerS.options;
      layerS.setStyle({
        ...currentStyle,
        fillColor: fillColor,
        weight: weight,
      });
    };

    const updateRegionLayers = (layersToUpdate: L.LayerGroup | null, selectedRegions: any[], defaultWeight: number) => {
      if (layersToUpdate) {
        layersToUpdate.eachLayer((layer: L.Layer) => {
          const region = (layer as any).feature.properties.region;
          const isSelected = selectedRegions.some(
            (c) => trimAndLowercase(c.name) === trimAndLowercase(region)
          );
          updateLayerStyle(layer, region, isSelected, defaultWeight);
        });
      }
    };

    if (mCurrentLevel.value === LayerLevel.REGION_SIXTEEN) {
      updateRegionLayers(layers.sixteenRegions, mSelectedSixteenRegions.value, defaultRegionLayerStyle.weight);
    } else if (mCurrentLevel.value === LayerLevel.REGION_TEN) {
      updateRegionLayers(layers.tenRegions, mSelectedSixteenRegions.value, defaultRegionLayerStyle.weight);
    } else if (
      mCurrentLevel.value === LayerLevel.CONSTITUENCY ||
      mCurrentLevel.value === LayerLevel.REGION_SIXTEEN_CONSTITUENCY ||
      mCurrentLevel.value === LayerLevel.REGION_TEN_CONSTITUENCY
    ) {
      if (layers.constituency) {
        layers.constituency.eachLayer((layer: L.Layer) => {
          const layerS = layer as L.Path
          const constituencyName = (layer as any).feature.properties.constituen;
          const constituencyNameNormalized = trimAndLowercase(constituencyName)

          let fillColor: string | undefined = unknown
          let weight: number = defaultConstituencyLayerStyle.weight

          if (mSelectedConstituencies.value.length >= 1) {
            mSelectedConstituencies.value.forEach((c) => {
              if (trimAndLowercase(c.name) === constituencyNameNormalized) {
                fillColor = getFillColor(constituencyNameNormalized)
                weight = selectedLayerStyle.weight
              }
            })
          } else {
            fillColor = getFillColor(constituencyNameNormalized)
            weight = defaultConstituencyLayerStyle.weight
          }

          const currentStyle = layerS.options
          layerS.setStyle({
            ...currentStyle,
            fillColor: fillColor,
            weight: weight
          })
        });
      }
    }
  }

  const fitBounds = () => {
    if (mMap.value && mNationalBounds.value) {
      zoomToFeature(mNationalBounds.value)
    }
  }

  function zoomToFeature(bounds: L.LatLngBounds) {
    mMap.value?.flyToBounds(bounds, {
      duration: 0.5, // Duration of animation in seconds
      easeLinearity: 0.25 // Smooth out the animation
    })
  }

  function onEachRegion(feature: any, layer: L.Layer) {
    const regionName = feature.properties.region

    layer.on({
      click: () => {
        mDoubleClicked.value = false;
        const isTenRegions = mCurrentLevel.value === LayerLevel.REGION_TEN;
        const regionNameNormalized = trimAndLowercase(regionName);

        // Choose the appropriate region data set based on the current level
        const regions = isTenRegions ? mDataStore.tenRegions : mDataStore.sixteenRegions;
        const selectedRegions = isTenRegions ? mSelectedTenRegions.value : mSelectedSixteenRegions.value;

        // Find the region that matches the normalized name
        const region = regions.find((r) => trimAndLowercase(r.name) === regionNameNormalized);

        if (region) {
          // Check if the region is already selected
          const regionAlreadySelected = selectedRegions.some(
            (r) => trimAndLowercase(r.name) === regionNameNormalized
          );

          // Add or remove the region from the selected list based on its current status
          const updatedRegions = regionAlreadySelected
            ? selectedRegions.filter((r) => trimAndLowercase(r.name) !== regionNameNormalized)
            : [...selectedRegions, region];

          // Update the appropriate selected regions variable based on the current level
          if (isTenRegions) {
            mSelectedTenRegions.value = updatedRegions;
          } else {
            mSelectedSixteenRegions.value = updatedRegions;
          }
        }

        // zoomToFeature(e.target.getBounds())
        // setActiveLayer(LayerLevel.CONSTITUENCY)
      },
      mouseover: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        const isTenRegions = mCurrentLevel.value === LayerLevel.REGION_TEN;
        const selectedRegions = isTenRegions ? mSelectedTenRegions.value : mSelectedSixteenRegions.value;

        // Proceed only if the region is not already selected
        if (!selectedRegions.includes(regionName)) {
          const currentStyle = layer.options;
          const newStyle = {
            ...currentStyle,
            weight: highlightLayerStyle.weight,
          };
          layer.setStyle(newStyle);

          layer.bindTooltip(
            `<h5>${regionName.toUpperCase()}</h5>`,
            { direction: 'top' }
          ).openTooltip();
        }

      },
      mouseout: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        const isTenRegions = mCurrentLevel.value === LayerLevel.REGION_TEN;
        const selectedRegions = isTenRegions ? mSelectedTenRegions.value : mSelectedSixteenRegions.value;
        if (!selectedRegions.includes(regionName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            weight: defaultRegionLayerStyle.weight
          }
          layer.setStyle(newStyle)
        }
      }
    })
  }

  function onEachConstituency(feature: any, layer: L.Layer) {
    const constituencyName = feature.properties.constituen.trim()

    layer.on({
      dblclick: (e) => {
        zoomToFeature(e.target.getBounds())
      },
      click: (e) => {
        const clickedLayer = e.target as L.Layer

        const constituencyNameNormalized = trimAndLowercase(constituencyName)
        const constituency = mDataStore.constituencies.find(
          (c) => trimAndLowercase(c.name) === constituencyNameNormalized
        )

        if (constituency) {
          // mSelectedConstituencies.value = removeFirstIfMoreThan(2, mSelectedConstituencies.value)
          const constituencyAlreadySelected = mSelectedConstituencies.value.some(
            (c) => trimAndLowercase(c.name) === constituencyNameNormalized
          )

          if (!constituencyAlreadySelected) {
            mSelectedConstituencies.value = [...mSelectedConstituencies.value, constituency]
          } else {
            mSelectedConstituencies.value = mSelectedConstituencies.value.filter(
              (c) => trimAndLowercase(c.name) !== constituencyNameNormalized
            )
          }
        }

        if (clickedLayer instanceof L.Path) {
          const currentStyle = clickedLayer.options
          const newStyle = {
            ...currentStyle,
            weight: selectedLayerStyle.weight,
            fillOpacity: selectedLayerStyle.fillOpacity
          }
          clickedLayer.setStyle(newStyle)
        }
      },
      mouseover: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        if (!mSelectedConstituencies.value.includes(constituencyName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            // weight: highlightLayerStyle.weight,
            fillOpacity: highlightLayerStyle.fillOpacity
          }
          layer.setStyle(newStyle)
        }
        layer.bindTooltip(
          `<h5>${constituencyName.toUpperCase()}</h5>`,
          { direction: 'top' }
        ).openTooltip()
      },
      mouseout: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        if (!mSelectedConstituencies.value.includes(constituencyName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            // weight: defaultConstituencyLayerStyle.weight,
            fillOpacity: defaultConstituencyLayerStyle.fillOpacity
          }
          layer.setStyle(newStyle)
        }
      }
    })
  }

  function toggleColorMode() {
    mDetailedColorMode.value = !mDetailedColorMode.value
  }

  function toggleLegend() {
    isLegendVisible.value = !isLegendVisible.value
  }

  return {
    currentLevel: mCurrentLevel,
    selectedSixteenRegions: mSelectedSixteenRegions,
    selectedTenRegions: mSelectedTenRegions,
    filteredConstituencies: mFilteredConstituencies,
    selectedConstituencies: mSelectedConstituencies,
    selectedGroupedData: mSelectedGroupedData,
    updatedConstituencies: mUpdatedConstituencies,
    updatedSelectedConstituencies: mUpdatedSelectedConstituencies,
    updatedSixteenRegions: mUpdatedSixteenRegions,
    updatedTenRegions: mUpdatedTenRegions,
    isLegendVisible,
    legendItems: mLegendItems,
    fitBounds,
    toggleColorMode,
    toggleLegend,
    initializeMap,
    initializeLayers,
    setActiveLayer,
    updateLayers,
    resetStore
  }
})
