export const trimAndLowercase = (input: string) => {
    return input.trim().toLowerCase();
}

export const trimAndUppercase = (input: string) => {
    return input.trim().toUpperCase();
}
export const sanitizeAndLowercaseString = (str: string): string => {
    return str
        .replace(/[^a-zA-Z]+/g, '') // Remove non-alphabetic characters and all whitespace
        .toLowerCase();             // Convert to lowercase
};



export const toSnakeCase = (str: string): string => {
    return str
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2') // Convert camelCase to snake_case
        .replace(/[\s\-\/]+/g, '_')            // Convert spaces, hyphens, and slashes to underscores
        .toLowerCase();                        // Convert the entire string to lowercase
};


export const toTitleCase = (str: string): string => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase in camelCase or PascalCase
        .replace(/[-_]+/g, ' ')              // Replace hyphens or underscores with spaces
        .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
}
