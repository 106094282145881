export enum PartyName {
    NPP = "NPP",
    NDC = "NDC",
    GUM = "GUM",
    CPP = "CPP",
    GFP = "GFP",
    GCPP = "GCPP",
    APC = "APC",
    LPG = "LPG",
    NDP = "NDP",
    KOFI_KORANTENG = "Kofi Koranteng",
    GEORGE_ADU = "George Adu",
    NANA_BEDIAKO = "Nana Bediako",
    ALAN_KYEREMANTEN = "Alan Kyeremanten",
    INDEPENDENT = "Independent"
}