import { defineStore } from 'pinia'
import { ref, watch, computed } from 'vue'

import type PartyVotes from '../../models/party_votes.model'
import type LeadingParty from '../../models/leading_party.model'
import type ElectionResult from '../../models/election-result.model'
import type { ElectionSummaryLive } from '../../models/election-summary.model'
import type AggregatedElectionResult from '../../models/aggregated_election_result.model'

import { ElectionType } from '../../enums/election-type.enum'
import { RegionType } from '@/modules/data/enums/region-type.enum'
import { LayerLevel } from '@/modules/map/enums/layer-level.enum'
import { PollType } from '../../enums/poll-type.enum'

import PresidentialElectionService from '../../services/presidential-election.service'
import ParliamentaryElectionService from '../../services/parliamentary-election.service'

import { trimAndLowercase } from '@/modules/core/utils/string.util'

import { usePresidentialMapLiveStore } from '@/modules/map/stores/live/presidential-map-live.store'
import { useParliamentaryMapLiveStore } from '@/modules/map/stores/live/parliamentary-map-live.store'

import { unknown } from '@/modules/map/utils/map-layer-styles'
import partyInfo from '@/assets/election/party_info.json'
import type Party from '../../models/party.model'

const presidentialElectionService = new PresidentialElectionService()
const parliamentaryElectionService = new ParliamentaryElectionService()

export const useElectionMapLiveStore = defineStore('electionMapLive', () => {
  const presidentialMapStore = usePresidentialMapLiveStore()
  const parliamentaryMapStore = useParliamentaryMapLiveStore()

  const mElectionResultsActual = ref<ElectionResult[]>([])
  const mElectionResultsExit = ref<ElectionResult[]>([])

  const mPollTypes = ref<string[]>(Object.values(PollType))
  const mSelectedPollType = ref<string>(PollType.ACTUAL_POLL)

  let mApiInterval: ReturnType<typeof setInterval> | null = null;


  watch(mElectionResultsActual, (selectedElection) => {
    if (selectedElection) {
      presidentialMapStore.updateLayers()
      presidentialMapStore.updatePollingStationsData(selectedElection)
    }
  })

  // watch(mParliamentaryElectionResults, (selectedElection) => {
  //   if (selectedElection) {
  //     parliamentaryMapStore.updateLayers()
  //   }
  // })

  // Start the interval
  function startInterval(electionType: ElectionType) {
    if (!mApiInterval) {
      mApiInterval = setInterval(electionType === ElectionType.PRESIDENTIAL ? getPresidentialElectionResultsS : getParliamentaryElectionResultsS, 30000); // 10 seconds
    }
  }

  const getPresidentialElectionResultsS = () => {
    console.log("Fetching presidential election results...");
    presidentialElectionService.getResultsMonday()
      .then((result) => {
        const electionResults = result.getOrNull();
        if (electionResults) {
          mElectionResultsActual.value = electionResults as ElectionResult[];
        }
      })
      .catch((error) => {
        console.error("Failed to fetch election results:", error);
      });
  };


  const getParliamentaryElectionResultsS = () => {
    parliamentaryElectionService.getResultsMonday().then(
      (result) => {
        if (result.getOrNull()) {
          mElectionResultsActual.value = result.getOrNull() as ElectionResult[]
        }
      }
    )
  }



  // Stop the interval
  function stopInterval() {
    if (mApiInterval) {
      clearInterval(mApiInterval);
      mApiInterval = null;
    }
  }

  const calculateVotePercentage = (partyVotes: number, result: PartyVotes[]): string => {
    const [firstLeadingParty, secondLeadingParty] = result
    const firstLeadingPartyVotes: number = firstLeadingParty.votes
    const secondLeadingPartyVotes: number = secondLeadingParty.votes

    const totalVotes = firstLeadingPartyVotes + secondLeadingPartyVotes

    return formatPercentage(partyVotes / totalVotes)
  }


  function calculateWinMargin(results: PartyVotes[]): { margin: number; marginPercentage: number } {
    if (results.length < 2) return { margin: 0, marginPercentage: 0 }
    const [first, second] = results
    const margin = first.votes - second.votes
    const marginPercentage = ((first.votesRatio ?? 0) - (second.votesRatio ?? 0)) * 100
    return { margin, marginPercentage }
  }

  const formatPercentage = (value: number): string => {
    return `${(value * 100).toFixed(2)}%`
  }

  const OTHER_PARTY_COLOR = '#DAA520'

  function summarizeElectionResults(electionType: ElectionType, results: ElectionResult[]): ElectionSummaryLive | null {
    const sortedResults = accumulateAndSortVotes(results)
    if (sortedResults.length < 2) return null

    let [first, second, third, forth, fifth] = sortedResults
    const other = sortedResults.slice(2);
    const otherParties: PartyVotes = {
      party: { name: 'Other' },
      votes: other.reduce((sum, party) => sum + party.votes, 0),
      votesRatio: other.reduce((sum, party) => sum + (party.votesRatio ?? 0), 0)
    }

    const partyVote = {
      party: { name: "N/A" } as Party,
      candidate: "N/A",
      votes: 0,
    };

    third = third ?? partyVote
    forth = forth ?? partyVote
    fifth = fifth ?? partyVote

    const firstLeadingParty = formatPartyData(electionType, first)
    const secondLeadingParty = formatPartyData(electionType, second)
    const thirdLeadingParty = formatPartyData(electionType, third)
    const fourthLeadingParty = formatPartyData(electionType, forth ?? partyVote)
    const fifthLeadingParty = formatPartyData(electionType, fifth ?? partyVote)
    const otherLeadingParty = formatPartyData(electionType, otherParties, true)

    return {
      firstLeadingParty: { ...firstLeadingParty, constituencyWon: countConstituenciesWonByParty(results, first.party.name) },
      secondLeadingParty: { ...secondLeadingParty, constituencyWon: countConstituenciesWonByParty(results, second.party.name) },
      thirdLeadingParty: { ...thirdLeadingParty, constituencyWon: countConstituenciesWonByParty(results, third.party.name) },
      fourthLeadingParty: { ...fourthLeadingParty, constituencyWon: countConstituenciesWonByParty(results, forth.party.name) },
      fifthLeadingParty: { ...fifthLeadingParty, constituencyWon: countConstituenciesWonByParty(results, fifth.party.name) },
      otherLeadingParty,
      ...calculateWinMargin(sortedResults)
    } as ElectionSummaryLive
  }

  function formatPartyData(electionType: ElectionType, party: PartyVotes, isOther: boolean = false): LeadingParty {
    const info = partyInfo.find((p) => p.name === party.party.name)
    return {
      name: party.party.name,
      votes: party.votes,
      percentage: ((party.votesRatio ?? 0) * 100).toFixed(1),
      constituencyWon: 0,
      image: isOther ? OTHER_PARTY_COLOR : (electionType === ElectionType.PRESIDENTIAL ? info?.candidate_image ?? unknown : info?.party_image ?? unknown),
      color: isOther ? OTHER_PARTY_COLOR : (info?.color ?? unknown)
    } as LeadingParty
  }

  function getRegions(electionType: ElectionType) {
    const store = electionType === ElectionType.PRESIDENTIAL ? presidentialMapStore : parliamentaryMapStore;
    const regionType = store.currentLevel === LayerLevel.REGION_SIXTEEN_CONSTITUENCY || LayerLevel.REGION_SIXTEEN ? RegionType.SIXTEEN : RegionType.TEN;
    return regionType === RegionType.SIXTEEN ? store.selectedSixteenRegions : store.selectedTenRegions;
  }

  function getSelectedConstituencies(electionType: ElectionType) {
    const store = electionType === ElectionType.PRESIDENTIAL ? presidentialMapStore : parliamentaryMapStore;
    return store.selectedConstituencies.length < 1 ? store.filteredConstituencies : store.selectedConstituencies;
  }


  function mElectionSummary(electionType: ElectionType) {
    return computed(() => {
      const constituencies = getSelectedConstituencies(electionType)
      const electionResults = mSelectedPollType.value === PollType.ACTUAL_POLL ? mElectionResultsActual.value : mElectionResultsExit.value;
      if (!electionResults) return null

      const filteredResults = constituencies.length < 1
        ? electionResults
        : electionResults.filter(result =>
          constituencies.some(constituency => trimAndLowercase(constituency.name) === trimAndLowercase(result.constituency))
        )

      console.log('getResultConstituency::constituencies:', filteredResults)
      const summary = summarizeElectionResults(electionType, filteredResults)
      // const aggregatedResult = getConstituencyResults(electionType, constituencies.map(c => c.name))
      return summary
    })
  }



  const mPresidentialElectionSummary = mElectionSummary(
    ElectionType.PRESIDENTIAL
  );

  const mParliamentaryElectionSummary = mElectionSummary(
    ElectionType.PARLIAMENTARY
  );

  function getElectionResults(electionType: ElectionType): ElectionResult[] {
    switch (electionType) {
      case ElectionType.PRESIDENTIAL:
        return mElectionResultsActual.value ?? []
      case ElectionType.PARLIAMENTARY:
        return mElectionResultsActual.value ?? []
      default:
        return []
    }
  }

  function filterElectionResults(results: ElectionResult[], filterKey: 'constituency' | 'region', filterValues: string[]): ElectionResult[] {
    return results.filter(
      (item) => item[filterKey] && filterValues.some(
        (filterValue) => trimAndLowercase(item[filterKey]) === trimAndLowercase(filterValue)
      )
    )
  }

  function aggregateElectionResults(results: ElectionResult[]): AggregatedElectionResult {
    const aggregatedResult: AggregatedElectionResult = {
      id: 0,
      registeredVoters: 0,
      totalCastVotes: 0,
      totalValidVotes: 0,
      totalRejectedVotes: 0,
      turnout: 0,
      region: '',
      constituency: '',
      pollingStationCode: '',
      pollingStation: '',
      partyVotes: []
    }

    results.forEach((result) => {
      aggregatedResult.registeredVoters += result.registeredVoters
      aggregatedResult.totalCastVotes += result.totalCastVotes
      aggregatedResult.totalValidVotes += result.totalValidVotes
      aggregatedResult.totalRejectedVotes += result.totalRejectedVotes
      aggregatedResult.turnout += result.turnout
    })

    aggregatedResult.partyVotes = accumulateAndSortVotes(results)

    return aggregatedResult
  }

  function getConstituencyResults(electionType: ElectionType, constituency: string[]): AggregatedElectionResult {
    const allResults = getElectionResults(electionType)
    const filteredResults = filterElectionResults(allResults, 'constituency', constituency)
    const aggregatedResult = aggregateElectionResults(filteredResults)
    aggregatedResult.constituency = ''
    return aggregatedResult
  }

  function getRegionResults(electionType: ElectionType, region: string, isTenRegions: boolean = false): AggregatedElectionResult {
    const allResults = getElectionResults(electionType)
    const regionNames = isTenRegions ? getRegionAliases(region) : [region];
    const filteredResults = filterElectionResults(allResults, 'region', regionNames)
    const aggregatedResult = aggregateElectionResults(filteredResults)
    aggregatedResult.region = region
    aggregatedResult.partyVotes = aggregatedResult.partyVotes.map(partyVote => ({
      ...partyVote,
      constCount: countConstituenciesWonByParty(filteredResults, partyVote.party.name)
    }))
    return aggregatedResult
  }

  function getResultRegion(region: string, isTenRegions: boolean = false) {
    const regionNames = isTenRegions ? getRegionAliases(region) : [region];
    let electionResults: ElectionResult[] = []

    const selectedElection = mSelectedPollType.value === PollType.ACTUAL_POLL ? mElectionResultsActual.value : mElectionResultsExit.value;

    electionResults = selectedElection?.filter(
      (item) => item.region && regionNames.some(
        (reg) => trimAndLowercase(item.region) === trimAndLowercase(reg)
      )
    ) || [];

    if (!electionResults || electionResults.length < 1) return []

    return accumulateAndSortVotes(electionResults)
  }

  function getRegionAliases(region: string): string[] {
    switch (trimAndLowercase(region)) {
      case 'northern':
        return ['northern', 'north east', 'savannah'];
      case 'brong ahafo':
        return ['bono', 'bono east', 'ahafo'];
      case 'volta':
        return ['volta', 'oti'];
      case 'western':
        return ['western', 'western north'];
      default:
        return [region];
    }
  }

  function getResultConstituency(constituency: string) {
    let electionResults: ElectionResult[] = []

    const selectedElection = mSelectedPollType.value === PollType.ACTUAL_POLL ? mElectionResultsActual.value : mElectionResultsExit.value;

    electionResults = selectedElection?.filter(
      (item) => item.constituency &&
        trimAndLowercase(item.constituency) === trimAndLowercase(constituency)
    ) || [];

    if (!electionResults || electionResults.length < 1) return []

    return accumulateAndSortVotes(electionResults)
  }

  function accumulateAndSortVotes(
    electionResults: ElectionResult[]
  ): PartyVotes[] {
    const accumulatedPartyVotes: PartyVotes[] = electionResults.reduce(
      (acc: PartyVotes[], result) => {
        result.partyVotes.forEach((partyVote) => {
          if (partyVote.party.name) {
            // Check if the party already exists in the accumulator
            const existingPartyVote = acc.find((pv) => pv.party.name === partyVote.party.name)
            if (existingPartyVote) {
              existingPartyVote.votes += partyVote.votes // Accumulate votes
            } else {
              // If not, add a new entry for the party
              acc.push({
                party: partyVote.party,
                candidate: partyVote.candidate,
                votes: partyVote.votes
              })
            }
          }
        })
        return acc
      },
      []
    )

    // Step 2: Sort the accumulated votes by number of votes in descending order
    const sortedVotes = accumulatedPartyVotes.sort((a, b) => b.votes - a.votes)

    // Step 3: Calculate total votes
    const totalVotes = sortedVotes.reduce((acc, partyVote) => acc + partyVote.votes, 0)

    // Step 4: Calculate the vote ratio for each party
    return accumulatedPartyVotes.map((partyVote) => ({
      ...partyVote,
      votesRatio: totalVotes > 0 ? partyVote.votes / totalVotes : 0
    }))
  }

  function countConstituenciesWonByParty(electionResults: ElectionResult[], partyName: string): number {

    // Step 1: Group results by constituency
    const constituencies = electionResults.reduce(
      (acc: { [key: string]: ElectionResult[] }, result) => {
        if (result.constituency) {
          if (!acc[result.constituency]) {
            acc[result.constituency] = []
          }
          acc[result.constituency].push(result)
        }
        return acc
      },
      {}
    )

    let constituenciesWon = 0

    // Step 2: For each constituency, accumulate votes and find the winner without sorting
    Object.values(constituencies).forEach((constituencyResults) => {
      const voteMap: { [partyName: string]: number } = {}

      // Accumulate votes for each party in this constituency
      constituencyResults.forEach((result) => {
        result.partyVotes.forEach((partyVote) => {
          if (partyVote.party.name) {
            voteMap[partyVote.party.name] = (voteMap[partyVote.party.name] || 0) + partyVote.votes
          }
        })
      })

      // Step 3: Find the party with the maximum votes
      let maxVotes = 0
      let winningParty = ''

      for (const [currentPartyName, votes] of Object.entries(voteMap)) {
        if (votes > maxVotes) {
          maxVotes = votes
          winningParty = currentPartyName
        }
      }

      // Step 4: Check if the winning party is the party we are interested in
      if (winningParty === partyName) {
        constituenciesWon++
      }
    })

    return constituenciesWon
  }


  const getPresidentialElectionResultsActual = async () => {
    const result = await presidentialElectionService.getResultsMonday()

    result.fold(
      (value) => {
        mElectionResultsActual.value = value as ElectionResult[]
        console.log('mElectionResultsActual:', mElectionResultsActual.value)
      },
      (error) => console.error(error)
    )
  }

  const getPresidentialElectionResultsExit = async () => {
    const result = await presidentialElectionService.getElectionResults(PollType.EXIT_POLL)

    result.fold(
      (value) => {
        mElectionResultsExit.value = value as ElectionResult[]
      },
      (error) => console.error(error)
    )
  }

  const getParliamentaryElectionResults = async () => {
    const result = await parliamentaryElectionService.getResultsMonday()

    result.fold(
      (value) => {
        mElectionResultsActual.value = value as ElectionResult[]

        console.log('getParliamentaryElectionResults::mElectionResultsActual:', mElectionResultsActual.value)
      },
      (error) => console.error(error)
    )
  }


  return {
    electionResults: mElectionResultsActual,
    electionResultsExit: mElectionResultsExit,
    presidentialElectionSummary: mPresidentialElectionSummary,
    parliamentaryElectionSummary: mParliamentaryElectionSummary,
    pollTypes: mPollTypes,
    selectedPollType: mSelectedPollType,
    getPresidentialElectionResultsActual,
    getPresidentialElectionResultsExit,
    getParliamentaryElectionResults,
    getResultRegion,
    getResultConstituency,
    getRegionResults,
    getConstituencyResults,
    calculateVotePercentage,
    calculateWinMargin,
    accumulateAndSortVotes,
    startInterval,
    stopInterval
  }
})
