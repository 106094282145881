const MapModule = () => import('./MapModule.vue')
import PresidentialMap from '@/modules/map/views/PresidentialMap.vue'
import ParliamentaryMap from '@/modules/map/views/ParliamentaryMap.vue'
import PresidentialMapLive from '@/modules/map/views/live/PresidentialMapLive.vue'
import ParliamentaryMapLive from '@/modules/map/views/live/ParliamentaryMapLive.vue'
import PresidentialParliamentaryMap from '@/modules/map/views/PresidentialParliamentaryMap.vue'
import VoterMap from '@/modules/map/views/VoterMap.vue'
import PredictionMap from '@/modules/map/views/PredictionMap.vue'
import VoronoiConstituencyMap from '@/modules/map/views/VoronoiConstituencyMap.vue'

export const mapModuleRoute = {
  name: 'map',
  path: '/map',
  component: MapModule,
  children: [
    {
      path: 'presidential',
      name: 'map.presidential',
      component: PresidentialMap
    },
    {
      path: 'parliamentary',
      name: 'map.parliamentary',
      component: ParliamentaryMap
    },
    {
      path: 'presidential-live',
      name: 'map.presidential.live',
      component: PresidentialMapLive
    },
    {
      path: 'parliamentary-live',
      name: 'map.parliamentary.live',
      component: ParliamentaryMapLive
    },
    {
      path: 'presidential-parliamentary',
      name: 'map.presidential.parliamentary',
      component: PresidentialParliamentaryMap
    },
    {
      path: 'registered-voters',
      name: 'map.registered.voters',
      component: VoterMap
    },
    {
      path: 'prediction',
      name: 'map.prediction',
      component: PredictionMap
    },
    {
      path: 'voronoi-constituency/:region/:constituency',
      name: 'map.voronoi.constituency',
      component: VoronoiConstituencyMap,
      props: true
    }
  ]
}
