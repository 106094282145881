<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import '@/assets/styles/election-summary/styles.css';

import type { ElectionSummaryLive } from '../models/election-summary.model';
import ElectoralCard from '@/modules/election/components/ElectoralCard.vue'
import ElectoralCardLite from '@/modules/election/components/ElectoralCardLite.vue'
import { hexToRgb } from "@/modules/core/utils/color.util";


interface Props {
    summary: ElectionSummaryLive | null;
}

const props = defineProps<Props>()

const firstLeadingPartyStyle = computed(() => ({
    color: props.summary?.firstLeadingParty.color as string
}))

const secondLeadingPartyStyle = computed(() => ({
    color: props.summary?.secondLeadingParty.color as string
}))

const thirdLeadingPartyStyle = computed(() => ({
    color: props.summary?.thirdLeadingParty.color as string
}))

const fourthLeadingPartyStyle = computed(() => ({
    color: props.summary?.fourthLeadingParty.color as string
}))

const fifthLeadingPartyStyle = computed(() => ({
    color: props.summary?.fifthLeadingParty.color as string
}))

const otherLeadingPartyStyle = computed(() => ({
    color: '#DAA520'
}))

const firstLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.firstLeadingParty.percentage}%`,
    backgroundColor: props.summary?.firstLeadingParty.color as string
}))

const secondLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.secondLeadingParty.percentage}%`,
    backgroundColor: props.summary?.secondLeadingParty.color as string
}))

const otherLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.otherLeadingParty.percentage}%`,
    backgroundColor: props.summary?.otherLeadingParty.color,
    left: `${props.summary?.firstLeadingParty.percentage}%`
}))

const otherLeadingPartyPercentage = computed(() => {
    const percentage = props.summary?.otherLeadingParty.percentage
    if (!percentage) return ''

    const percentageNum = Number(percentage)
    return percentageNum > 10 ? `${percentage}%` : ''
})

watch(props, (summary) => {
    if (summary) {
        chartData.value = setChartData();
        chartOptions.value = setChartOptions();
    }
})

onMounted(() => {
    chartOptions.value = setChartOptions();
});

const chartData = ref();
const chartOptions = ref();
function getBackgroundColor(partyColor?: string, opacity: number = 1): string {
    const color = hexToRgb(partyColor ?? '')
    return `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${opacity})`
}

const setChartData = () => {
    const firstLeadingPartyName = props.summary?.firstLeadingParty.name;
    const secondLeadingPartyName = props.summary?.secondLeadingParty.name;

    const firstLeadingPartyPercentage = Number(props.summary?.firstLeadingParty.percentage);
    const secondLeadingPartyPercentage = Number(props.summary?.secondLeadingParty.percentage);

    const firstLeadingPartyColor = getBackgroundColor(props.summary?.firstLeadingParty.color);
    const secondLeadingPartyColor = getBackgroundColor(props.summary?.secondLeadingParty.color);
    return {
        labels: [firstLeadingPartyName, secondLeadingPartyName],
        datasets: [
            {
                backgroundColor: [firstLeadingPartyColor, secondLeadingPartyColor],
                borderColor: [firstLeadingPartyColor, secondLeadingPartyColor],
                data: [firstLeadingPartyPercentage, secondLeadingPartyPercentage],
                barPercentage: 0.1,
                barThickness: 4,
                maxBarThickness: 6,
                minBarLength: 2,
            }
        ]
    };
};
const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    return {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: 0.5,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary,
                    font: {
                        weight: 500
                    }
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    };
}

</script>
<template>
    <div class="election-results">
        <div class="results-bar">

            <ElectoralCard :style="firstLeadingPartyStyle" :name="props.summary?.firstLeadingParty.name"
                party="Democratic" :electoral-votes="props.summary?.firstLeadingParty.constituencyWon"
                :popular-votes="props.summary?.firstLeadingParty.votes"
                :image-url="props.summary?.firstLeadingParty.image" :color="props.summary?.firstLeadingParty.color" />

            <!-- Results Section -->
            <div class="results-section">
                <div class="bar">
                    <div class="first-leading-party-bar" :style="firstLeadingPartyBarStyle">
                        <span class="percentage-label">{{ props.summary?.firstLeadingParty.percentage }}%</span>
                    </div>

                </div>
            </div>
            <div class="results-section">
                <div class="bar">
                    <div class="second-leading-party-bar" :style="secondLeadingPartyBarStyle">
                        <span class="percentage-label">{{ props.summary?.secondLeadingParty.percentage }}%</span>
                    </div>
                </div>
            </div>

            <ElectoralCard :style="secondLeadingPartyStyle" :name="props.summary?.secondLeadingParty.name"
                party="Democratic" :electoral-votes="props.summary?.secondLeadingParty.constituencyWon"
                :popular-votes="props.summary?.secondLeadingParty.votes"
                :image-url="props.summary?.secondLeadingParty.image" :color="props.summary?.secondLeadingParty.color" />

            <!-- Results Section -->
            <div>
                <div class="win-margin">
                    Win margin:
                    <span class="margin-number">{{ props.summary?.margin.toLocaleString()
                        }}</span>
                    votes (<span class="margin-percentage">{{
                        props.summary?.marginPercentage.toFixed(2)
                        }}%</span>)
                </div>
                <div class="others" :style="otherLeadingPartyStyle">
                    <div class="candidate-info">
                        <span class="vote-count">OTHERS</span>
                        <span class="vote-percentage"> | {{
                            props.summary?.otherLeadingParty.percentage }}%</span>
                        <span class="vote-count"> | {{
                            props.summary?.otherLeadingParty.votes.toLocaleString()
                            }}</span>
                    </div>
                </div>
            </div>

            <div class="electoral-cards-container">
                <ElectoralCardLite :style="thirdLeadingPartyStyle" :name="props.summary?.thirdLeadingParty.name"
                    party="Democratic" :electoral-votes="props.summary?.thirdLeadingParty.constituencyWon"
                    :popular-votes="props.summary?.thirdLeadingParty.votes"
                    :image-url="props.summary?.thirdLeadingParty.image"
                    :color="props.summary?.thirdLeadingParty.color" />


                <ElectoralCardLite :style="fourthLeadingPartyStyle" :name="props.summary?.fourthLeadingParty.name"
                    party="Democratic" :electoral-votes="props.summary?.fourthLeadingParty.constituencyWon"
                    :popular-votes="props.summary?.fourthLeadingParty.votes"
                    :image-url="props.summary?.fourthLeadingParty.image"
                    :color="props.summary?.fourthLeadingParty.color" />


                <ElectoralCardLite :style="fifthLeadingPartyStyle" :name="props.summary?.fifthLeadingParty.name"
                    party="Democratic" :electoral-votes="props.summary?.fifthLeadingParty.constituencyWon"
                    :popular-votes="props.summary?.fifthLeadingParty.votes"
                    :image-url="props.summary?.fifthLeadingParty.image"
                    :color="props.summary?.fifthLeadingParty.color" />
            </div>
        </div>
    </div>
</template>


<style scoped>
.electoral-cards-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 1rem;
    box-sizing: border-box;
}

/* Ensure each card takes up equal width with consistent spacing */
.electoral-cards-container>* {
    flex: 1;
    margin: 0;
}

.second-leading-party-bar {
    left: 0;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .electoral-cards-container {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .electoral-cards-container>* {
        width: 100%;
        max-width: 200px;
    }
}


.candidate-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 13px;
}
</style>
