import { mapMetaFromMetaDTO } from "@/modules/core/mappers/meta.mappers";
import type { ParliamentarySlipDto, PresidentialSlipDto, SlipsDto } from "../dtos/slip.dto";
import type { ParliamentarySlip, PresidentialSlip, Slips } from "../models/slip.model";

export function mapSlipsFromSlipsDTO(dto?: SlipsDto | null): Slips | null {
    return !dto
        ? null
        : {
            data: dto.data.map((dto) => mapSlipFromPresidentialSlipDto(dto as PresidentialSlipDto)),
            meta: mapMetaFromMetaDTO(dto.meta)
        } as Slips
}

export function mapSlipFromPresidentialSlipDto(dto?: PresidentialSlipDto | null): PresidentialSlip | null {
    return !dto
        ? null
        : {
            id: dto.id,
            serialNumber: dto.serial_number,
            pollingStationCode: dto.polling_station_code,
            pollingStation: dto.polling_station_name,
            constituency: dto.constituency_name,
            region: dto.region_name,
            npp: dto.npp,
            ndc: dto.ndc,
            gum: dto.gum,
            cpp: dto.cpp,
            gfp: dto.gfp,
            gcpp: dto.gcpp,
            apc: dto.apc,
            lpg: dto.lpg,
            ndp: dto.ndp,
            kofiKoranteng: dto.kofi_koranteng,
            georgeAdu: dto.george_adu,
            nanaBediako: dto.nana_bediako,
            alanKyeremanten: dto.alan_kyeremanten,
            independent: dto.independent,
            totalBallotsIssuedA1: dto.total_number_of_ballots_issued_a1,
            registerVotersB1: dto.number_of_voters_on_register_b1,
            ballotsIssuedVotersC1: dto.number_of_ballots_issued_to_voters_c1,
            rejectedBallotD1: dto.rejected_ballot_d1,
            spoiltBallotC3: dto.spoilt_ballot_c3,
            totalVotesCastRecordedSnc: dto.total_votes_cast_recorded_snc,
            totalValidBallotsRecordedSna: dto.total_valid_ballots_recorded_sna,
            biometricVerificationDevice: dto.biometric_verification_device,
            manualVerification: dto.manual_verification,
            pinkSheetStampStatus: dto.pink_sheet_stamp,
            voterName: dto.voter_name,
            date: dto.date,
            time: dto.time,
            isVerified: dto.is_verified,
            imageUrl: dto.image_url,
            createdAt: new Date(dto.created_at),
            link: dto.link,
        } as PresidentialSlip
}

export function mapSlipFromParliamentarySlipDto(dto?: ParliamentarySlipDto | null): ParliamentarySlip | null {
    return !dto
        ? null
        : {
            id: dto.id,
            serialNumber: dto.serial_number,
            pollingStationCode: dto.station_code,
            pollingStation: dto.station_name,
            constituency: '',
            region: '',
            candidate1Name: dto.candidate1_name,
            candidate1Score: dto.candidate1_score,
            candidate2Name: dto.candidate2_name,
            candidate2Score: dto.candidate2_score,
            candidate3Name: dto.candidate3_name,
            candidates3Score: dto.candidate3_score,
            candidate4Name: dto.candidate4_name,
            candidate4Score: dto.candidate4_score,
            candidate5Name: dto.candidate5_name,
            candidate5Score: dto.candidate5_score,
            candidate6Name: dto.candidate6_name,
            candidate6Score: dto.candidate6_score,
            candidate7Name: dto.candidate7_name,
            candidate7Score: dto.candidate7_score,
            candidate8Name: dto.candidate8_name,
            candidate8Score: dto.candidate8_score,
            candidate9Name: dto.candidate9_name,
            candidate9Score: dto.candidate9_score,
            candidate10Name: dto.candidate10_name,
            candidate10Score: dto.candidate10_score,
            candidate11Name: dto.candidate11_name,
            candidate11Score: dto.candidate11_score,
            candidate12Name: dto.candidate12_name,
            candidate12Score: dto.candidate12_score,

            totalBallotsIssuedA1: dto.total_number_of_ballots_issued_a1,
            registerVotersB1: dto.number_of_voters_on_register_b1,
            ballotsIssuedVotersC1: dto.number_of_ballots_issued_to_voters_c1,
            rejectedBallotD1: dto.rejected_ballot_d1,
            spoiltBallotC3: dto.spoilt_ballot_c3,
            totalVotesCastRecordedSnc: dto.total_votes_cast_recorded_snc,
            totalValidBallotsRecordedSna: dto.total_valid_ballots_recorded_sna,
            biometricVerificationDevice: dto.biometric_verification_device,
            manualVerification: dto.manual_verification,
            pinkSheetStampStatus: dto.pink_sheet_stamp,
            voterName: dto.voter_name,
            date: dto.date,
            time: dto.time,
            isVerified: dto.is_verified,
            imageUrl: dto.image_url,
            createdAt: new Date(dto.created_at),
            link: dto.link,
        } as ParliamentarySlip
}
