import Result from '@/modules/core/models/result.class'
import axios from 'axios'
import {
  mapElectionFromElectionDTO,
  mapElectionResultFromElectionResultDTO,
  mapElectionResultFromPresidentialElectionResultDto,
  mapElectionResultFromSlipDTO
} from '../mappers/election.mapper'
import type ElectionDto from '../dtos/election.dto'

import presidentialResult from '@/assets/election/presidential_result.json'
import { openInsightApi } from '@/modules/core/services/api-openinsight.service'
import { openPollApi } from '@/modules/core/services/api-openpoll.service'
import type { PresidentialSlipDto, SlipsDto } from '../dtos/slip.dto'
import { mapSlipFromPresidentialSlipDto } from '../mappers/slip.mappers'
import { PollType } from '../enums/poll-type.enum'
import { VerificationStatus } from '../enums/verification-status.enum'
import type { ListResponse } from '@/modules/core/interfaces/api-response.interfaces'
import type { ElectionResultDto, PresidentialElectionResultDto } from '../dtos/election-result.dto'

export default class PresidentialElectionService {
  async getElections() {
    try {
      const responses = await Promise.all(
        presidentialResult.map((e: string) => {
          return axios.get<ElectionDto>(`/data/election/presidential_result/${e}.json`)
        })
      )
      const elections = []
      for (const response of responses) {
        const election = response.data
        elections.push(election)
      }
      const mappedElections = elections.map((dto) =>
        mapElectionFromElectionDTO(dto)
      )
      return Result.success(mappedElections)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.error(error)
      return Result.failure(error)
    }
  }

  async getConstituencyElectionResults() {
    try {
      const response = await axios.get<ElectionResultDto[]>('/data/election/presidential_result_2020.json')

      const mappedElections = response.data.map(mapElectionResultFromElectionResultDTO)
      return Result.success(mappedElections)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getConstituencyElectionResultsRemote() {
    try {
      const response = await openPollApi.get<ListResponse<PresidentialElectionResultDto>>('/v1/PresidentialResults/MondayPollingCenter')

      const mappedElections = response.data!.map(mapElectionResultFromPresidentialElectionResultDto)

      return Result.success(mappedElections)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getSlips(paginate: boolean = false) {
    try {
      const url = paginate ? '/v1/slips' : '/v1/fetch_all_slips'
      const response = await openInsightApi.get<{ slips: SlipsDto }>(url)
      const electionResults = response.slips.data.map((dto) => mapSlipFromPresidentialSlipDto(dto as PresidentialSlipDto))
      return Result.success(electionResults)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getElectionResults(pollType: PollType) {
    try {
      const url = pollType === PollType.ACTUAL_POLL ? '/v1/fetch_all_slips' : '/v1/fetch_all_slips'
      const response = await openInsightApi.get<{ slips: SlipsDto }>(url)
      const electionResults = response.slips.data.map((dto) => mapElectionResultFromSlipDTO(dto as PresidentialSlipDto))
      return Result.success(electionResults)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.error(error)
      return Result.failure(error)
    }
  }

  async getResultsMonday(status: VerificationStatus = VerificationStatus.VERIFIED) {
    try {
      const url = status ? `/v1/PresidentialResults?status=${status}` : '/v1/PresidentialResults'
      const response = await openPollApi.get<ListResponse<PresidentialElectionResultDto>>(url)
      const electionResults = response.data!.map(mapElectionResultFromPresidentialElectionResultDto)
      return Result.success(electionResults)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

}
